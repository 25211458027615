import { insertQualifyingCheck } from "@/apis"
import ClaimSteps, { ClaimType } from "@/steps"
import { ClaimStatus } from "@/types"
import type { QualifyingCheck } from "."

export const SelfAssessedCheck: QualifyingCheck = ({ claim }) => {
	// Self assessed - go to step 101. Cannot process claim.
	if (CurrentStep.type === ClaimType.Standard && claim.selfAssessed === true) {
		claim.ClaimStatus = ClaimStatus.CancelledSelfAssessed
		void insertQualifyingCheck("Are you self-assessed?", "yes", false, claim.ClaimId, "SA")
		return ClaimSteps.RequireSelfAssessment
	}

	if (CurrentStep.type === ClaimType.Standard && claim.selfAssessed === false) {
		void insertQualifyingCheck("Are you self-assessed?", "no", true, claim.ClaimId, "SA")
	} else {
		void insertQualifyingCheck("Are you self-assessed?", "unknown", false, claim.ClaimId, "SA")
	}
	return true
}
