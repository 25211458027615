import { defineGlobals } from "vue-utils"
import { fixHeadFoot } from "@/utils/miscPageUtils"

export function scrollTo(elementId: string) {
	const offset = $(`#${elementId}`).offset()
	if (offset === undefined) {
		console.warn(`Unknown element '#${elementId}'`)
		return
	}
	$("html, body").animate({ scrollTop: offset.top - 190 }, 300)
}

export function scrollToTop() {
	$("#Claim").off("hidden.bs.collapse")
	$("html, body").animate(
		{
			scrollTop: 0,
		},
		300
	)
}

export function focusClaimForm(openClaim: boolean) {
	const element = document.getElementById("Claim")
	if (!element) {
		document.location.href = "/"
	} else {
		if (openClaim) {
			App.IsQuickEstimate = false
		}
		fixHeadFoot()
		scrollTo("Claim")
	}
}
defineGlobals({ focusClaimForm })

defineGlobals({ scrollToTop, scrollTo })
