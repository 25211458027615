import ButtonNavigation from "@/components/ButtonNavigation"
import Question from "@/components/Question"
import RadioButtons from "@/components/RadioButtons"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const QualifyingStep: ReactiveComponent = () => {
	return () => (
		<>
			{App.CustomerClaim.Married && !App.CustomerClaim.MarriageClaimSkipped && (
				<Question question="Have you ever claimed for marriage allowance transfer?">
					<RadioButtons
						name="marriage-allowance-transfer"
						value={App.CustomerClaim.MarriageAllowanceTransfer}
						setValue={(value) => (App.CustomerClaim.MarriageAllowanceTransfer = value)}
						required
					/>
				</Question>
			)}

			<Question question="Have you claimed uniform allowance in your current job?">
				<RadioButtons
					name="uniform-allowance"
					value={App.CustomerClaim.ClaimedUniformAllowanceCurrentJob}
					setValue={(value) => (App.CustomerClaim.ClaimedUniformAllowanceCurrentJob = value)}
					required
				/>
			</Question>

			<Question question="Have you claimed uniform allowance in a previous job you had in the last 4 years?">
				<RadioButtons
					name="uniform-allowance-past"
					value={App.CustomerClaim.ClaimedUniformAllowancePreviousJob}
					setValue={(value) => (App.CustomerClaim.ClaimedUniformAllowancePreviousJob = value)}
					required
				/>
			</Question>

			<ButtonNavigation />
		</>
	)
}

export default defineComponent(QualifyingStep)
