import { onMounted, ref } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"

declare const Trustpilot: {
	loadFromElement(element: Element): void
}

const TrustPilotDisplay: ReactiveComponent = () => {
	const devRef = ref<Element>()

	onMounted(() => {
		if (devRef.value) {
			Trustpilot.loadFromElement(devRef.value)
		}
	})

	return () => (
		<div
			ref={devRef}
			class="trustpilot-widget"
			data-locale="en-GB"
			data-template-id="53aa8912dec7e10d38f59f36"
			data-businessunit-id={trustPilotBusinessUnitId}
			data-style-height="130px"
			data-style-width="100%"
			data-theme="light"
			data-stars="5"
		>
			<a href={`https://uk.trustpilot.com/review/${brand.DomainName}`} target="_blank">
				Trustpilot
			</a>
		</div>
	)
}

export default defineComponent(TrustPilotDisplay)
