import { insertQualifyingCheck } from "@/apis"
import ClaimSteps, { ClaimType } from "@/steps"
import { ClaimStatus } from "@/types"
import type { QualifyingCheck } from "."

export const ClaimedBeforeCheck: QualifyingCheck = ({ claim }) => {
	if (CurrentStep.type === ClaimType.Standard && App.CustomerClaim.ClaimedBefore) {
		claim.ClaimStatus = ClaimStatus.CancelledClaimedBefore
		void insertQualifyingCheck("Have you claimed before?", "yes", false, App.CustomerClaim.ClaimId, "REP")

		return ClaimSteps.AlreadyClaimed
	}

	void insertQualifyingCheck("Have you claimed before?", "no", true, App.CustomerClaim.ClaimId, "REP")
	return true
}
