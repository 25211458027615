import { defineComponent, type ReactiveComponent } from "vue-utils"

const DetailsRejected: ReactiveComponent = () => {
	return () => (
		<>
			<div class="col-12 mb-1 text-center">
				<h3 class="bold">Your Claim</h3>
			</div>
			<div class="col-12 mb-1 mt-3">
				<p>Sorry but we are unable to process your claim at this time.</p>
				<p></p>
			</div>
		</>
	)
}

export default defineComponent(DetailsRejected)
