import ButtonNavigation from "@/components/ButtonNavigation"
import { validateInternationalMobile, validateUKMobile } from "@/utils/validation"
import { ref, watch } from "vue"
import { defineComponent, useCustomValidation, useInputValidation, type ReactiveComponent } from "vue-utils"
import AddressInput from "./components/AddressInput"
import SplitDateInput from "./components/SplitDateInput"

type TelType = "uk" | "intl"

const AddressStep: ReactiveComponent = () => {
	const telRef = ref<HTMLInputElement>()
	const telType = ref<TelType>(App.CustomerClaim.Phone === "" || validateUKMobile(App.CustomerClaim.Phone) ? "uk" : "intl")

	useInputValidation(telRef, () => {
		if (App.CustomerClaim.noPhone) {
			return true
		}
		if (telType.value === "uk") {
			if (App.CustomerClaim.Phone === "" || !validateUKMobile(App.CustomerClaim.Phone)) {
				return "Please provide a valid UK phone number"
			}
		} else if (App.CustomerClaim.Phone === "" || !validateInternationalMobile(App.CustomerClaim.Phone)) {
			return "Please provide a valid phone number"
		}
		return true
	})

	function updateSelfAssessed() {
		//When changing the self assessed option to "no" or "not sure", need to reset the SAC options
		const selfAssessed = App.CustomerClaim.selfAssessed

		if (selfAssessed === false || selfAssessed === null) {
			const claim = App.CustomerClaim as unknown as Record<string, unknown>
			for (const key in App.CustomerClaim) {
				if (key.startsWith("SAC")) {
					claim[key] = selfAssessed
				}
			}
		}
	}
	watch(() => App.CustomerClaim.selfAssessed, updateSelfAssessed)

	useCustomValidation(() => {
		if (!App.CustomerClaim.DateOfBirth) return "Please enter your date of birth"
		const MaxYear = new Date().getFullYear() - 15
		const MinYear = new Date().getFullYear() - 100
		const DoB = new Date(App.CustomerClaim.DateOfBirth)
		if (DoB.getFullYear() < MinYear || DoB.getFullYear() > MaxYear) return "Please enter a valid date of birth"
		if (App.CustomerClaim.Address1 === "") return "Please ensure you have chosen your address"
		return true
	})

	return () => (
		<>
			<div class="col-12 claim-label bold">Are you required to prepare a self-assessment tax return?</div>
			<div class="col-12 text-center my-2">
				<label class="custom-control custom-control-inline custom-radio">
					<input type="radio" name="selfassessed" class="custom-control-input" v-model={App.CustomerClaim.selfAssessed} value={false} required />
					<span class="custom-control-label">No</span>
				</label>
				<label class="custom-control custom-control-inline custom-radio">
					<input type="radio" name="selfassessed" class="custom-control-input" v-model={App.CustomerClaim.selfAssessed} value={true} required />
					<span class="custom-control-label">Yes</span>
				</label>
				<label class="custom-control custom-control-inline custom-radio">
					<input type="radio" name="selfassessed" class="custom-control-input" v-model={App.CustomerClaim.selfAssessed} value={null} required />
					<span class="custom-control-label">Not sure</span>
				</label>
			</div>

			<AddressInput address={App.CustomerClaim} updateAddress={(data) => Object.assign(App.CustomerClaim, data)} />

			<div class="col-12 my-2 col-md-6">
				<SplitDateInput
					class="mx-0 px-0"
					date={App.CustomerClaim.DateOfBirth}
					setDate={(date) => (App.CustomerClaim.DateOfBirth = date)}
					placeholder="Your date of birth"
					label="Your date of birth"
					required
				/>
			</div>

			<div class="col-12 my-2 col-md-5">
				{App.CustomerClaim.noPhone ? (
					<input ref={telRef} key="no-phone-input" type="tel" class="form-control" placeholder="Mobile phone number" value="" disabled />
				) : (
					<div style="display: flex;">
						<select class="form-control" v-model={telType.value} style="width: 6rem;">
							<option value="uk">UK</option>
							<option value="intl">Intl</option>
						</select>
						<input
							ref={telRef}
							type="tel"
							v-model={App.CustomerClaim.Phone}
							class="form-control"
							id="inp_phone_23"
							v-mask={telType.value === "uk" ? "##### ######" : undefined}
							placeholder="Mobile phone number"
							required
						/>
					</div>
				)}
			</div>
			<div class="col-12 my-2 col-md-2 text-center">
				<strong>OR</strong>
			</div>

			<div class="col-12 my-2 col-md-5">
				<label class="custom-control custom-control-inline custom-checkbox">
					<input type="checkbox" v-model={App.CustomerClaim.noPhone} name="noPhone" class="custom-control-input" />
					<span class="custom-control-label">I don't have a mobile phone</span>
				</label>
			</div>

			<ButtonNavigation />
		</>
	)
}

export default defineComponent(AddressStep)
