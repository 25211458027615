import { showConfirmModal } from "@/modals"
import { closeClaimClearData } from "@/utils/clearClaim"
import { defineComponent, type ReactiveComponent } from "vue-utils"

async function confirmCloseClaim() {
	return !(await showConfirmModal({
		title: "Save your claim on this device?",
		content: () => (
			<>We've saved your claim data on this device so you can easily complete your claim later. Would you like to keep this data?</>
		),
		cancelText: "No",
		confirmText: "Yes",
	}))
}

const CloseClaimButton: ReactiveComponent = () => {
	async function handleClick() {
		if (await confirmCloseClaim()) {
			closeClaimClearData()
		}
	}

	return () => (
		<button type="button" class="btn btn-sm btn-outline-primary" id="ClaimClose" onClick={() => void handleClick()}>
			<i class="fa fa-close" />
		</button>
	)
}

export default defineComponent(CloseClaimButton)
