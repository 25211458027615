import { checkEmailDidYouMean } from "@/apis"
import { showConfirmModal } from "@/modals"
import type { Id } from "@/types"
import type { MaybeRef } from "@vueuse/core"
import { unref } from "vue"
import { useCustomCheck } from "vue-utils"
import { useLoading } from "./useLoading"

async function confirmChangeEmail(currentEmail: string, didYouMean: string) {
	return await showConfirmModal({
		title: "Possible email correction",
		content: () => (
			<>
				We've detected that it's possible your email address may have been mistyped.
				<br />
				<br />
				We suggest changing it from <span style="font-weight:bold;">{currentEmail}</span> to <span style="font-weight:bold;">{didYouMean}</span>.
				<br />
				<br />
				Would you like to change it?
			</>
		),
		cancelText: "No, don't change it",
		confirmText: "Yes, change it",
	})
}

export const useEmailDidYouMean = (claimId: MaybeRef<Id>, getEmail: () => string, setEmail: (newEmail: string) => void) => {
	const { runAction } = useLoading()

	useCustomCheck(async () => {
		const currentEmail = getEmail()
		const didYouMean = await runAction(checkEmailDidYouMean(unref(claimId), currentEmail))
		if (!didYouMean || didYouMean.length === 0) {
			return true
		}

		const changeEmail = await confirmChangeEmail(currentEmail, didYouMean)
		if (changeEmail) {
			setEmail(didYouMean)
		}

		return true
	})
}
