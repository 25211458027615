import { insertQualifyingCheck } from "@/apis"
import calculatorConfig from "@/calculatorConfig"
import ClaimSteps, { ClaimType } from "@/steps"
import { ClaimStatus } from "@/types"
import type { QualifyingCheck } from "."

export const UniformSubscriptionsCheck: QualifyingCheck = ({ claim }) => {
	//if customer is married and doesn't qualify in their own right then skip this check - we'll let them pass because them
	//they might be due a marriage refund
	const { ClaimedUniformAllowanceCurrentJob, ClaimedUniformAllowancePreviousJob, Subscriptions } = claim
	const earningsBand = claim.EarningsBand ? calculatorConfig.earningsBands[claim.EarningsBand] : null
	const qualifies = earningsBand?.qualifies ?? false
	const possMarriageClaim = claim.Married && ((claim.SpouseEmployed && !qualifies) || (!claim.SpouseEmployed && qualifies))

	if (possMarriageClaim) {
		return true
	}

	if (
		CurrentStep.type === ClaimType.Standard &&
		(ClaimedUniformAllowanceCurrentJob || ClaimedUniformAllowancePreviousJob) &&
		(!Subscriptions || Subscriptions.length === 0)
	) {
		claim.ClaimStatus = ClaimStatus.CancelledWorksPartTime
		void insertQualifyingCheck("Claimed uniform and not in a subscription industry?", "yes", false, claim.ClaimId, "UNIF")
		return ClaimSteps.UniformClaimed
	}

	void insertQualifyingCheck("Claimed uniform and not in a subscription industry?", "no", true, claim.ClaimId, "UNIF")
	return true
}
