import ClaimSteps from "@/steps"
import { delay } from "vue-utils"
import { awaitNavPop, getHistoryState } from "./history"
import { goToNextStep, goToPreviousStep } from "./navigation"
import { ignoreNavigation } from "./state"

window.addEventListener("popstate", (event) => {
	if (ignoreNavigation.value) {
		return
	}
	const type = getHistoryState(event.state)
	if (type === "back") {
		void handleBrowserBack()
	} else if (type === "forward") {
		void handlerBrowserForward()
	}
})

let isSubmitting = false

async function handleBrowserBack() {
	if (CurrentStep === ClaimSteps.InitialStep) {
		ignoreNavigation.value = true

		while (getHistoryState(history.state) !== null) {
			await awaitNavPop()
			await delay(5)
		}

		ignoreNavigation.value = false
		return
	}

	ignoreNavigation.value = true
	history.forward()
	ignoreNavigation.value = false

	if (!isSubmitting) {
		try {
			isSubmitting = true
			await goToPreviousStep(undefined, { ignoreHistory: true })
		} finally {
			isSubmitting = false
		}
	}
}

async function handlerBrowserForward() {
	ignoreNavigation.value = true
	await awaitNavPop()
	ignoreNavigation.value = false

	if (App.InitialLoadWithExistingClaim) {
		App.InitialLoadWithExistingClaim = false
		App.IsQuickEstimate = false
	} else if (!isSubmitting) {
		try {
			isSubmitting = true
			await goToNextStep(undefined, { ignoreHistory: true })
			console.log("submitting done")
		} finally {
			isSubmitting = false
		}
	}
}
