export const earningsMonthlyThreshold = 1000
export const teacherIndustryIds = [205, 206, 201]
export const moreDetailIndustryIds = [103, 207]
export const pandemicYearIds = [18, 19]
export const modIndustryId = 203

/**
 * Total number of years someone can select for their job start date before the option changes to "Before xxxx"
 */
export const selectableEmploymentYears = 5

export const ErrorsSteps = {
	ClaimedBefore: 100,
	SelfAssessed: 101,
	EarningTooLow: 102,
	NotPaidTaxLongEnough: 103,
	NothingToClaimFor: 104,
	WorkPartTime: 105,
	UniformClaimed: 106,
}
export const modEmployers = [
	"hm forces",
	"h m forces",
	"army",
	"h.m forces",
	"hm forces (army)",
	"hm forces (army) ",
	"mod",
	"royal air force",
	"british army",
	"royal navy",
	"armed forces",
	"army ",
	"soldier",
	"hm forces - brittish army",
	"armed forces ",
	"hm forces - army",
	"h.m forces (army)",
	"hm forces army",
	"british army (hm forces)",
	"ministry of defence",
	"h&m armed forces",
	"mod (army)",
	"hm forces ",
	"mod army",
	"british armed forces",
	"army hmf",
	"royal air force ",
	"h.m.forces",
	"hmforce",
	"hm forces (a)",
	"brithish army",
	"british army, military of defence",
	"army hm government",
	"m.o.d",
	"royal maines",
	"4 rifles, army",
	"hm armed forces",
	"hm royal navy",
	"hm forces (raf)",
	"army force",
	"salvation army",
	"british army/nhs",
	"army, 3 para, hm forces",
	"hm force",
	"brirish army",
	"mod royal navy",
	"british army forces",
	"armed force",
	"hm forcesc",
	"hm forces(army)",
	"mistry of defence",
	"hm frorce",
	"h.m forces ",
	"hm forces, babock flagship",
	"armed forces (army)",
	"royal marines.",
	"mod army ",
	"uk armed forces",
	"military",
	"royal navy ",
	"royal navy - armed forces",
	"hm armed forces ",
	"1rgr, shornecliffe folkestone",
	"british army mod",
	"hmforces",
	"h.m armed forces",
	"hm force (army) ",
	"hm armforce",
	"arm forces",
	"m o d",
	"british army ",
	"h m force",
	"mod ",
	"hm froces ",
	"hm fources ",
	"hm force ",
	"south east reserve forces and cadets association",
	"royal marines",
	"h.m. forces",
	"h m forces ",
	"british army (reserves)",
	"british forces united kingdom",
	"mod, army",
	"1 rgr",
	"h m forces army",
	"hm forces british army",
	"mod uk",
	"hmg forces",
	"brtish army",
	"hf forces",
	"british forces army",
	"hm forces, army",
	"hm forces military",
	"hmf",
	"h m force ",
	"united kingdom",
	"1rgr",
	"hms force (army)",
	"iss fs defence",
	"hm ministry of defence",
	"hmg",
	"hgm ",
	"british army forces ",
	"mod army( mpgs)",
	"hm forcre",
	"hm arm force",
	"hm arm forces",
	"hm forces,",
	"mod brigade of gurkhas",
	"hm forces mod",
	"hm forces-army",
	"mod ,army",
	"mod- army",
	"britsih army",
	"hm forces -army",
	"hm forces- army",
	"h,m forses",
	"army (mod)",
	"m.o.d.",
	"goverment service",
	"hm fources",
	"the royal navy",
	"mod/army",
	"her majesty government",
	"army welfare service",
	"royal navy(mod)",
	"maj bridges ra",
	"mh forces",
	"1st royal gurkha rifles",
	"hm's forces (army)",
	"mod british army",
	"h. m. forces",
	"hmf forces",
	"hm forces army ",
	"1 rrf",
	"hm foces ",
	"royal navy (hm forces)",
	"britsh army",
	"hms, ",
	"hms",
	"hms forces",
	"army cadet force",
	"hm forces (queen's gurkha signal)",
	"hm forces uk",
	"fm forces",
	"mod-army",
	"hm armed forces (army)",
	"m.o.d(10 qoglr)",
	"armed forces, army",
	"mod(army)",
	"hmf army",
	"mod/ (qoglr)",
	"`hm forces",
	"army mod",
	"mod police",
	"mod(n)",
	"hm. forces",
	"mdpga, hm force",
	"hm forces - army other ranks",
	"hm forces           ",
	"hm armed forces 2 signal regiment",
	"miinistry of defence",
	"army-mod",
	"armed forces uk",
	"hm forcess",
	"hm forces / army",
	"m.o.d (army)",
	"hm forses",
	"mod - army",
	"hm forcese",
	"h m forces (army)",
	"brittish army",
	"hm forces (mod)",
	"army hm forces",
	"h mforces",
	"h m forces, ",
	"mod royal air force",
	"dfrmo (mod)",
	"mod (royal navy)",
	"hm forces navy and army",
	"army forces",
	"royal airforce",
	"m.o.d fire service",
	"ministry of defence police",
	"britishe army",
	"hm force (mod)",
	"h.m. force",
	"hm forse",
	"hm forces-uk",
	"10 regt (qoglr)",
	"forces",
	"royal navy/ nhs",
]
