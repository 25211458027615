import type { ClaimCustomer } from "@/types"

export enum ClaimType {
	Standard = "standard",
	MoD = "mod",
	Marriage = "marriage",
}

export interface ClaimStepData {
	step: number
	name: string
	type: ClaimType
	isIssue: boolean
	getNextStep?: () => ClaimStepData | null | "auto"
	getPreviousStep?: () => ClaimStepData | null | "auto"
	shouldSkipStep?: (claim: ClaimCustomer) => boolean
}
