import usePostcodeLookup from "@/composition/usePostcodeLookup"
import { fixPostcode, isPostcodeValid } from "@/utils/postcode"
import { toReactive } from "@vueuse/core"
import { computed, ref, toRef, watch } from "vue"
import { defineComponent, inferProps, type ReactiveComponent } from "vue-utils"
import PostcodeInput from "./PostcodeInput"

interface AddressLike {
	Address1: string
	Address2: string
	Address3: string
	County: string
	Postcode: string
}

interface Props {
	address: AddressLike
	updateAddress(data: Partial<AddressLike>): void
}

const AddressInput: ReactiveComponent<Props> = (props) => {
	const { addresses, lookupPostcode, matchAddress } = usePostcodeLookup()

	const address = toReactive(toRef(props, "address"))
	const hasAddress = computed(() => !!address.Address1 && address.Address1.length > 0)

	const addressId = ref<string>("")
	async function lookUpAddresses(replaceExisting: boolean) {
		const results = await lookupPostcode(address.Postcode)
		addressId.value = results.find((result) => hasAddress.value && result.StreetAddress.startsWith(address.Address1))?.AddressId ?? ""

		if (replaceExisting) {
			props.updateAddress({
				Address1: "",
				Address2: "",
				Address3: "",
				County: "",
				Postcode: fixPostcode(address.Postcode),
			})
		}
	}
	watch(addressId, () => void selectAddress())

	async function selectAddress() {
		const address = await matchAddress(addressId.value)
		if (address) {
			props.updateAddress({
				Address1: address.Address1,
				Address2: address.Address2,
				Address3: address.Address3,
				County: address.County,
			})
		}
	}

	if (isPostcodeValid(address.Postcode)) {
		void lookUpAddresses(false)
	}

	return () => (
		<>
			<div class="col-12 my-2">
				<select v-model={addressId.value} class="form-control" required={!hasAddress.value}>
					<option value="" disabled selected>
						{!address.Postcode || address.Postcode.trim().length === 0 ? "Please enter your postcode" : "Please choose your address"}
					</option>
					{addresses.value.map((address) => (
						<option key={address.AddressId} value={address.AddressId}>
							{address.StreetAddress}
						</option>
					))}
				</select>
			</div>
			<div class="col-12">
				{hasAddress.value && (
					<div class="row" id="address">
						<div class="col-12 my-2">
							<input type="text" class="form-control" value={address.Address1} readonly placeholder="Address line 1" />
						</div>
						<div class="col-12 my-2">
							<input type="text" class="form-control" value={address.Address2} readonly placeholder="Address line 2" />
						</div>
						<div class="col-12 my-2">
							<input type="text" class="form-control" value={address.Address3} readonly placeholder="Address line 3" />
						</div>
						<div class="col-12 my-2">
							<input type="text" class="form-control" value={address.County} readonly placeholder="County" />
						</div>
					</div>
				)}
			</div>

			<div class="col-12 my-2 col-md-6">
				<PostcodeInput
					postcode={address.Postcode ?? ""}
					setPostcode={(postcode) => props.updateAddress({ Postcode: postcode })}
					onChange={() => void lookUpAddresses(true)}
				/>
				<i class="fa fa-search overlaybutton" />
			</div>
		</>
	)
}

export default defineComponent(AddressInput, inferProps<Props>())
