import ButtonNavigation from "@/components/ButtonNavigation"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const EmployerDetailsNeeded: ReactiveComponent = () => {
	return () => (
		<>
			<div class="col-12 mb-4 text-center">
				<h3 class="bold">
					<i class="fa fa-info"></i> Employer Details Required
				</h3>
			</div>
			<p class="alert alert-warning">
				Because you have not been able to look up your employer from our database of employers we are not able to help you at this time.
				Unfortunately HMRC require detailed employer information without which your claim cannot be processed. If you would like to look again
				for your employer, please use the back button below.
			</p>

			<ButtonNavigation hideNext />
		</>
	)
}

export default defineComponent(EmployerDetailsNeeded)
