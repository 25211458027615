import { defineComponent, type ReactiveComponent } from "vue-utils"
import EditSignature from "./components/EditSignature"

const SignatureDetails: ReactiveComponent = () => {
	const termsEl = document.getElementById("calculator-terms-2") as HTMLTemplateElement

	//TODO Make sure you can't submit without checking the boxes
	return () => (
		<>
			<div class="col-12 mt-2 text-center">
				<h2 class="bold">Contractual Agreement to Claim</h2>
				<br />
				<label class="custom-control custom-control-inline custom-checkbox">
					<input
						type="checkbox"
						class="custom-control-input required"
						v-model={App.CustomerClaim.AcceptedTerms}
						name="terms"
						id="inp_accept_terms"
						required
					/>

					<span class="custom-control-label">
						I agree to One Time Refund's{" "}
						<a href="/terms" target="_blank">
							terms and conditions
						</a>
						.{" "}
						<a href="/terms" style="text-decoration: underline !important;" target="_blank">
							Click here to see the full terms and conditions
						</a>
						.
					</span>
				</label>
				<hr />
				<label class="custom-control custom-control-inline custom-checkbox">
					<input
						type="checkbox"
						class="custom-control-input required"
						v-model={App.CustomerClaim.HMRCPermission}
						name="hmrc"
						id="inp_hmrc_permission"
						required
					/>

					<span class="custom-control-label">
						I give permission that information I have submitted will be submitted to HMRC as a part of a claims pack that can will delivered by
						courier to their offices, as soon as a complete claim is constructed.
					</span>
				</label>
				<hr />
				<div class="col-12 my-1 p-2 px-md-3 bg-light">
					<div class="row">
						<div class="col-12 scrollableInfo">
							<small innerHTML={termsEl.innerHTML} />
						</div>
					</div>
				</div>
			</div>

			<EditSignature setSignatureSVG={(svg) => (App.CustomerClaim.SignatureSVG = svg)} />
		</>
	)
}

export default defineComponent(SignatureDetails)
