import { defineComponent, type ReactiveComponent } from "vue-utils"
import { showTrustPilotModal } from "@/utils/miscPageUtils"

const LeaveAReview: ReactiveComponent = () => {
	function openTrustPilot() {
		void showTrustPilotModal(App.CustomerClaim.ClaimId, App.CustomerClaim.TrustPilotSiteUrl)
	}

	return () => (
		<>
			<p>How easy was that? If you're happy with our service please leave a 5 star review:</p>
			<p class="text-center">
				<button onClick={openTrustPilot} class="btn btn-success">
					I would give it 5 stars
				</button>
			</p>
		</>
	)
}

export default defineComponent(LeaveAReview)
