import moment from "moment"
import { defaultCustomerClaim, defaultMODClaim } from "./defaults"
import ClaimSteps, { type ClaimStepData, ClaimType } from "./steps"
import type { ClaimCustomer, MODClaim } from "./types"
import { clearLocalStorage } from "./utils/clearClaim"

function matchStepFromLocalStorage(): ClaimStepData | null {
	const savedStageStr = localStorage.getItem("ClaimStage")
	const savedTypeStr = localStorage.getItem("ClaimType")

	if (!savedStageStr || !savedTypeStr || !(Object.values(ClaimType) as string[]).includes(savedTypeStr)) {
		return null
	}

	return Object.values(ClaimSteps).find((step) => step.name === savedStageStr && step.type === savedTypeStr) ?? null
}

export default function initClaim() {
	CurrentStep = matchStepFromLocalStorage() ?? ClaimSteps.InitialStep

	if (savedClaim === null) {
		App.CustomerClaim = defaultCustomerClaim
		if (getParamValue("married") === "0") {
			App.CustomerClaim.Married = false
			App.CustomerClaim.MarriageClaimSkipped = true
		}
	} else {
		App.CustomerClaim = savedClaim
		localStorage.setItem("CustomerClaim", JSON.stringify(savedClaim))
		CurrentStep = ClaimSteps.Estimate

		if (testMode) console.log(`found saved claim data status ${savedClaim.ClaimStatus}`)
	}
	App.MODClaim = defaultMODClaim

	if (localStorage.getItem("CustomerClaim") !== null) {
		App.CustomerClaim = JSON.parse(localStorage.getItem("CustomerClaim") as string) as ClaimCustomer
		const conversionpage = window.location.href.indexOf(conversionUrl) > -1
		if (conversionpage) {
			if (testMode) {
				console.log("On conversion page - hold your horses - don't go clearing any local storage data just yet...")
			}
		}

		// Remove old claims without new properties (will error)
		if (typeof App.CustomerClaim.TaxYearStatuses === "undefined" || App.CustomerClaim.TaxYearStatuses === null) {
			localStorage.removeItem("CustomerClaim")
			localStorage.removeItem("ClaimStage")
			window.location.href = "/"
		}

		if (App.CustomerClaim.BrandId === brand.BrandId || App.CustomerClaim.SourceBrandId === brand.BrandId) {
			/////if (true) {
			//check date of localStorage data and clear if older than 30 days
			const today = new Date()
			if (testMode) console.log(App.CustomerClaim.DateStamp)
			if (App.CustomerClaim.DateStamp === null) {
				if (testMode) console.log("no date found in local storage claim data so add property and re-save to local storage...")

				if (testMode) App.CustomerClaim.DateStamp = moment(today.getDate()).add(-31, "days").toISOString()
				else App.CustomerClaim.DateStamp = moment(today.getDate()).toISOString()

				localStorage.setItem("CustomerClaim", JSON.stringify(App.CustomerClaim))
			}
			const dataDate = moment(App.CustomerClaim.DateStamp)
			console.log(dataDate)
			const now = moment()
			console.log(now)
			const diff = now.diff(dataDate, "days")
			let maxdiff = App.CustomerClaim.ClaimStatus >= 10 ? 1 : 14 //if claim is already complete then clear after 1 day
			if (testMode) maxdiff = 1
			console.log(`Local storage is ${diff} days old`)
			if (diff > maxdiff && !conversionpage) {
				if (testMode) console.log("clearing storage cos its older than today... (you're in test mode!)")
				clearLocalStorage()
				App.CustomerClaim = defaultCustomerClaim
				CurrentStep = ClaimSteps.InitialStep
			}
		} else if (CurrentStep !== ClaimSteps.FinalStep) {
			// Don't use !== above since types of the variables above are not the same
			// If claim relates to a different brand from the site it was created on and it's incomplete
			// (e.g.teacher claim submitted on UTR) then clear it
			if (testMode) console.log("Clearing local storage as brand doesn't match source brand")
			clearLocalStorage()
			App.CustomerClaim = defaultCustomerClaim
		}
	}
	if (localStorage.getItem("MODClaim") !== null) {
		App.MODClaim = JSON.parse(localStorage.getItem("MODClaim") as string) as MODClaim
	}

	if (suppressUBOptin) {
		App.CustomerClaim.ThirdPtyPermission = false
	}
}

function getParamValue(key: string) {
	const urlParams = new URLSearchParams(window.location.search)
	const value = urlParams.get(key)
	return value
}
