import { defineGlobals } from "vue-utils"
import "./browserHistoryHandler"
import { setClaimStep } from "./setStep"

export * from "./getStep"
export * from "./navigation"
export * from "./setStep"
export * from "./state"

defineGlobals({ setClaimStage: setClaimStep, setClaimStep })
