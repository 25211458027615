import { logCustomerIOEvent, saveClaimToServer } from "@/apis"
import { useLoading } from "@/composition/useLoading"
import { modEmployers, modIndustryId } from "@/constants"
import ClaimSteps, { type ClaimStepData, ClaimType } from "@/steps"
import { ClaimStatus } from "@/types"
import { getLatestJob } from "@/utils/jobDates"
import { checkFields } from "@/utils/validation"
import { getNextStep, getPreviousStep } from "./getStep"
import runPostSaveActions from "./saveStep"
import { type SetClaimSetOptions, setClaimStep } from "./setStep"

export async function goToNextStep(nextStep?: ClaimStepData, options?: SetClaimSetOptions): Promise<boolean> {
	const handler = App.ValidationHandler
	if (!handler) {
		throw new Error("Validation handler is not set up. Are you in a step?")
	}

	const ok = await checkFields(handler)
	if (!ok) {
		return false
	}

	if (!nextStep && !getNextStep(CurrentStep)) {
		return false
	}

	//check for MOD
	//THIS IS WHERE WE DEFINE THE PARAMETERS THAT DEFINE A 'MOD' CLAIM
	// Check for employer name that could be MOD and change claim accordingly
	if (CurrentStep.type === ClaimType.Standard && CurrentStep.step >= ClaimSteps.Employer.step && isMoDClaim()) {
		nextStep = ClaimSteps.BasicInformation
	}

	if (CurrentStep.type === ClaimType.Standard && CurrentStep.step >= ClaimSteps.Signature.step && CurrentStep.step < ClaimSteps.FinalStep.step) {
		App.CustomerClaim.ClaimStatus = ClaimStatus.AbandonedBeforePayment
	}

	App.CustomerClaim = await useLoading().runAction(saveClaimToServer(App.CustomerClaim))
	await runPostSaveActions(nextStep, options)

	return true
}

function isMoDClaim(): boolean {
	const latestJob = getLatestJob(App.CustomerClaim.Employers)
	if (!latestJob) {
		return false
	}
	const employerName = latestJob.EmployerName.trim().toLowerCase()
	return latestJob.IndustryId === modIndustryId || modEmployers.some((employer) => employer === employerName)
}

export async function goToPreviousStep(previousStep?: ClaimStepData, options?: SetClaimSetOptions) {
	if (!previousStep) {
		const previous = getPreviousStep(CurrentStep)
		if (!previous) {
			return false
		}
		previousStep = previous
	}

	// If claim status was changed to an error status then change it back
	if (App.CustomerClaim.ClaimStatus > 20 && previousStep.step < 50) {
		App.CustomerClaim.ClaimStatus = ClaimStatus.AbandonedBeforePayment
	}

	await setClaimStep(previousStep, options)

	//if (ClaimStage > 1 || window.location.hash.indexOf('step') > -1) {
	/*if (ClaimStage > getClaimStepNo('job') || window.location.hash.indexOf('step') > -1) {
        var hashString = '#step' + ClaimStage;
        history.pushState('', '', hashString);
    }*/

	const event = {
		name: "click_back",
		data: {
			step_number: previousStep.step,
		},
	}
	void logCustomerIOEvent(App.CustomerClaim.ClaimId, event)
	return true
}
