import type { Id } from "@/types"
import { computed } from "vue"
import { defineComponent, inferProps, useOnInputNumber, type ReactiveComponent } from "vue-utils"

interface Props {
	industryId: Id
	setIndustryId(id: Id): void
}

const IndustrySelect: ReactiveComponent<Props> = (props) => {
	const industries = computed(() => [...App.industrySectors].sort((i1, i2) => i1.Key.localeCompare(i2.Key)))
	return () => (
		<select class="form-control" value={props.industryId < 0 ? "" : props.industryId} onInput={useOnInputNumber(props.setIndustryId)} required>
			<option value="" disabled>
				Select an Industry
			</option>
			{industries.value.map((industry) => (
				<option key={industry.Value} value={industry.Value}>
					{industry.Key}
				</option>
			))}
		</select>
	)
}

export default defineComponent(IndustrySelect, inferProps<Props>())
