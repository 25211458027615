import type { ClaimStepData } from "@/steps"
import { getStepComponent } from "@/steps/StepComponents"
import { h, markRaw, onBeforeMount, onUnmounted, provide, renderSlot, shallowRef } from "vue"
import { defineComponent, inferProps, ValidationHandler, ValidationHandlerKey, type ReactiveComponent } from "vue-utils"

interface Props {
	step: ClaimStepData
}

const ClaimStep: ReactiveComponent<Props> = (props, { slots }) => {
	const formRef = shallowRef<HTMLFormElement>()
	const validationHandler = new ValidationHandler({ form: formRef })
	provide(ValidationHandlerKey, validationHandler)

	markRaw(validationHandler)

	onBeforeMount(() => (App.ValidationHandler = validationHandler))
	onUnmounted(() => (App.ValidationHandler = undefined))

	function renderComponent() {
		if ("default" in slots) {
			return renderSlot(slots, "default")
		}
		const component = getStepComponent(props.step)
		if (!component) {
			return null
		}
		return h(component)
	}
	return () => (
		<form
			ref={formRef}
			class="d-flex flex-wrap step p"
			data-step-name={props.step.name ?? ""}
			data-step-no={props.step.step}
			data-claim-type={props.step.name}
			onSubmit={(e) => e.preventDefault()}
		>
			{renderComponent()}
		</form>
	)
}

export default defineComponent(ClaimStep, inferProps<Props>())
