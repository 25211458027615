import { css } from "vite-css-in-js"

export const employerContainerStyles = css`
	overflow: hidden;
	margin: 0 auto;
	display: flex;
	flex-direction: row;
	gap: 2rem;
	align-items: center;
	justify-items: last baseline;
	transition: all 0.2s ease-in-out;
`

export const employerListStyles = css`
	list-style: none;
	margin: 1rem auto;
	padding: 0;
	width: 310px;
	text-align: center;

	&[data-open="true"] {
		li button {
			opacity: 0.6;
		}
	}

	> li {
		display: inline-block;
		margin: 0;
		padding: 0.1em;
		text-align: center;

		&.none {
			display: block;
			margin-top: 0.5rem;
			padding-top: 0.5rem;
			border-top: 1px solid #fff;
		}
	}
`

export const employerListButtonStyles = css`
	color: #fff;
	font-weight: 600;
	transition: all 0.4s ease-in-out;
	border: 1px solid #fff;
	padding: 0.3em 0.8em;
	background: none;
	position: relative;
	border-radius: 0.2em;
	background-color: rgba(0, 0, 0, 0.3);

	&.dark {
		color: #000;
		&:hover,
		&:active {
			color: #000;
		}
	}

	&:hover,
	&:active {
		color: #333;
		background-color: #fff;
	}

	&[data-active="true"] {
		color: rgb(243, 109, 33);
		border: solid 1px #fff;
		background-color: #fff;
		border-radius: 0.2em;
		opacity: 1 !important;
	}
`
