import { showAlertModal } from "@/modals"
import type { Nullable } from "@/types"
import moment from "moment"
import { delay, ValidationHandler } from "vue-utils"

/*VALIDATION FUNCTIONS*/
export async function checkFields(validationHandler: ValidationHandler): Promise<boolean> {
	const result = await validationHandler.validateForm()
	if (result.successful) {
		return true
	}

	const errorMessages = result.errorMessages ?? []
	if (errorMessages.length > 0) {
		await showAlertModal({
			title: "Missing information",
			content: () => (
				<ul style={{ margin: 0, listStyle: "none", padding: 0 }}>
					{errorMessages.map((msg, i) => (
						<li key={i}>{msg}</li>
					))}
				</ul>
			),
		})
		await delay(250)
		return false
	}

	return false
}

export function decodeHTML(html: string) {
	const txt = document.createElement("textarea")
	txt.innerHTML = html
	return txt.value
}

export function validateEmail(email: string) {
	const re =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return re.test(email)
}

export function validateInternationalMobile(inp: string) {
	const re = /([+0])(\d{1,4})(\s?(\d{1,4}))?(\s?\(?\d{1,4}\)?\s?)?(\d{1,4}(\s?\d{1,4}){1,2}\s?){1,2}(\d{3}\s?\d{3})/
	return re.test(inp)
}

export function validateUKMobile(inp: string) {
	const re = /((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}/
	return re.test(inp)
}

export function validateNI(NI: string) {
	const re = /^[A-CEGHJ-PR-TW-Z]{1}[A-CEGHJ-NPR-TW-Z]{1}[0-9]{6}[A-DFM]{0,1}$/
	return re.test(NI)
}

export function isValidDate(str: Nullable<string>): { valid: boolean; tidiedDate: string } {
	//return valid for empty string as null is acceptable...
	if (!str || str.length === 0) return { valid: true, tidiedDate: "" }

	//fix 2 digit years
	const a = str.split("/")
	let y = a[a.length - 1]
	if (testMode) console.log(y)
	if (y.length === 2) {
		const yint = parseInt(y)
		if (yint > 70 && yint <= 99) {
			y = `19${y}`
		} else {
			y = `20${y}`
		}
		a[a.length - 1] = y
		str = a.join("/")
		if (testMode) console.log(str)
	}

	let d = moment(str, "D/M/YYYY")
	if (!d || !d.isValid()) {
		//Try a different format
		str = `01/${str}`
		d = moment(str, "D/M/YYYY")
	}
	if (!d.isValid()) {
		return {
			valid: false,
			tidiedDate: "",
		}
	}

	const OK =
		str.indexOf(d.format("D/M/YYYY")) >= 0 ||
		str.indexOf(d.format("DD/MM/YYYY")) >= 0 ||
		str.indexOf(d.format("D/M/YY")) >= 0 ||
		str.indexOf(d.format("DD/MM/YY")) >= 0

	return { valid: OK, tidiedDate: str }
}
