import { defineGlobals } from "vue-utils"

function testimonialIframeModal(heading: string, url: string) {
	let modal: JQuery<HTMLElement>
	const iframe = /*html*/ `<div class="embed-responsive embed-responsive-16by9" style="background-color:#eee;">
        <iframe width="100%" src="${url}" frameborder="0" allowfullscreen>
            Loading
        </iframe>
    </div>`
	if ($("#testimonialIframeModal").length === 0) {
		modal = $('<div id="testimonialIframeModal" class="modal fade" tabindex="-1" role="dialog" />')
		modal.html(/*html*/ `
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">${heading}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><i class="fa fa-times"></i></span>
                        </button>
                    </div>
                    <div class="modal-body subheading text-center">
                        ${iframe}
                    </div>
                </div>
            </div>
        `)
		$("body").append(modal)
	} else {
		modal = $("#testimonialIframeModal")
		modal.html(/*html*/ `
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">${heading}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><i class="fa fa-times"></i></span>
                        </button>
                    </div>
                    <div class="modal-body subheading text-center">
                        ${iframe}
                    </div>
                </div>
            </div>
        `)
	}
	modal.on("hidden.bs.modal", () => {
		$("#testimonialIframeModal").modal("dispose")
		$("#testimonialIframeModal").remove()
	})
	modal.modal()
}

defineGlobals({ testimonialIframeModal })

export default testimonialIframeModal
