import { defineGlobals } from "vue-utils"

function testimonialContentModal(heading: string, content: string, imageUrl: string) {
	let modal: JQuery<HTMLElement>
	const img = /*html*/ `
        <img
            src="${imageUrl}" 
            alt="${heading}" 
            class="d-block w-100" 
            style="object-fit:cover; object-position:50% 0; height:30vw; min-height:175px; max-height:375px;" 
        />`
	if ($("#testimonialContentModal").length === 0) {
		modal = $('<div id="testimonialContentModal" class="modal fade" tabindex="-1" role="dialog" />')
		modal.html(/*html*/ `
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">${heading}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><i class="fa fa-times"></i></span>
                        </button>
                    </div>
                    <div class="modal-body subheading text-center">
                        ${img}
                        <div class="text-left pt-3" style="font-size:0.9rem;">
                            ${content}
                        </div>
                    </div>
                </div>
            </div>
        `)
		$("body").append(modal)
	} else {
		modal = $("#testimonialContentModal")
		modal.html(/*html*/ `
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">${heading}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true"><i class="fa fa-times"></i></span>
                        </button>
                    </div>
                    <div class="modal-body subheading text-center">
                        ${img}
                        <div class="text-left pt-3" style="font-size:0.9rem;">
                            ${content}
                        </div>
                    </div>
                </div>
            </div>
        `)
	}
	modal.on("hidden.bs.modal", () => {
		$("#testimonialContentModal").modal("dispose")
		$("#testimonialContentModal").remove()
	})
	modal.modal()
}

defineGlobals({ testimonialContentModal })

export default testimonialContentModal
