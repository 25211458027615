import ButtonNavigation from "@/components/ButtonNavigation"
import { goToNextFocusable } from "@/composition/useNext"
import { validateInternationalMobile, validateUKMobile } from "@/utils/validation"
import { ref } from "vue"
import { defineComponent, useCustomValidation, useInputValidation, type ReactiveComponent } from "vue-utils"

type TelType = "uk" | "intl"

const NationalInsuranceStep: ReactiveComponent = () => {
	const telRef = ref<HTMLInputElement>()
	const telType = ref<TelType>(App.CustomerClaim.Phone === "" || validateUKMobile(App.CustomerClaim.Phone) ? "uk" : "intl")

	useInputValidation(telRef, () => {
		if (App.CustomerClaim.noPhone) {
			return true
		}
		if (telType.value === "uk") {
			if (App.CustomerClaim.Phone === "" || !validateUKMobile(App.CustomerClaim.Phone)) {
				return "Please provide a valid UK phone number"
			}
		} else if (App.CustomerClaim.Phone === "" || !validateInternationalMobile(App.CustomerClaim.Phone)) {
			return "Please provide a valid phone number"
		}
		return true
	})

	function niValue(index: number) {
		const ni = App.CustomerClaim.NI
		return ni.length <= index ? "" : App.CustomerClaim.NI.charAt(index)
	}
	function setNiValue(index: number, char: string) {
		const ni = App.CustomerClaim.NI.padEnd(index + 1, " ")
		App.CustomerClaim.NI = ni.substring(0, index) + char.toUpperCase() + ni.substring(index + 1)
		App.CustomerClaim.NI = App.CustomerClaim.NI.substring(0, 9)
	}
	function isCharacterInput(index: number) {
		return index === 0 || index === 1 || index === 8
	}
	function checkNIDigit(value: string, index: number) {
		return value.length > 0 && (isCharacterInput(index) ? /[a-z]/i.test(value) : /[0-9]/.test(value))
	}
	useCustomValidation(() => {
		//const niReg = /^[a-ceghj-pr-tw-zA-CEGHJ-PR-TW-Z]{1}[a-ceghj-pr-tw-zA-CEGHJ-NPR-TW-Z]{1}[0-9]{6}[a-dfmA-DFM]{0,1}$/
		// https://stackoverflow.com/questions/10204378/regular-expression-to-validate-uk-national-insurance-number
		const niReg = /^(?!BG|GB|NK|KN|TN|NT|ZZ)[A-CEGHJ-PR-TW-Z][A-CEGHJ-NPR-TW-Z](?:\s?\d){6}\s?[A-DM ]$/i
		const niResult = niReg.test(App.CustomerClaim.NI)
		return niResult ? true : "Please enter a valid NI number"
	})

	return () => (
		<>
			<div class="col-12 my-2 text-center">
				<h3 class="bold">Final Details</h3>
			</div>
			<div class="col-12 claim-label">
				In order to process your claim we will need your National Insurance Number and your phone number.
				<br />
			</div>
			<div class="col-12 text-center">
				<p class="bold" style="margin-bottom: 0;">
					National Insurance Number (eg AA 123456 B)
				</p>
			</div>
			<div class="col-12 my-2 form-inline justify-content-center flex-nowrap">
				{Array.from({ length: 9 }, (_, i) => (
					<input
						key={i}
						type={isCharacterInput(i) ? "text" : "number"}
						value={niValue(i)}
						onInput={(e) => {
							const target = e.target as HTMLInputElement
							setNiValue(i, target.value)
							if (checkNIDigit(target.value, i)) {
								goToNextFocusable(target)
							}
						}}
						placeholder={isCharacterInput(i) ? "X" : "0"}
						maxlength={1}
						class="form-control fragment required"
						required
						v-mask={isCharacterInput(i) ? "A" : "#"}
					/>
				))}
				<br />
			</div>
			<div class="col-12 my-2 text-center">
				{!App.CustomerClaim.noPhone ? (
					<>
						<p class="bold" style="margin-bottom: 0;">
							Mobile Phone
						</p>
						<div style="display: flex;">
							<select class="form-control" v-model={telType.value} style="width: 6rem;">
								<option value="uk">UK</option>
								<option value="intl">Intl</option>
							</select>
							<input
								ref={telRef}
								type="tel"
								v-model={App.CustomerClaim.Phone}
								class="form-control"
								id="inp_phone_23"
								v-mask={telType.value === "uk" ? "##### ######" : undefined}
								placeholder="Mobile phone number"
								required
							/>
						</div>
					</>
				) : (
					<>
						<p class="bold" style="margin-bottom: 0;">
							Mobile Phone
						</p>
						<input type="tel" class="form-control" placeholder="Mobile phone number" disabled />
					</>
				)}
			</div>

			<ButtonNavigation />
		</>
	)
}

export default defineComponent(NationalInsuranceStep)
