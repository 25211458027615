/* eslint-disable @typescript-eslint/no-unsafe-member-access */

export type ClaimHistoryType = "back" | "current" | "forward"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getHistoryState(state: any): ClaimHistoryType | null {
	if (!state) {
		return null
	}
	if (!("claimHistoryType" in state && "step")) {
		return null
	}

	if (state.claimHistoryType !== "back" && state.claimHistoryType !== "forward" && state.claimHistoryType !== "current") {
		return null
	}
	return state.claimHistoryType as ClaimHistoryType
}

export function createHistoryState(type: "back" | "forward" | "current") {
	return {
		claimHistoryType: type,
	}
}

export function awaitNavPop() {
	return new Promise<PopStateEvent>((resolve) => {
		function onPopState(e: PopStateEvent) {
			window.removeEventListener("popstate", onPopState)
			resolve(e)
		}
		window.addEventListener("popstate", onPopState)
		history.back()
	})
}
