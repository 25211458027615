export enum ClaimStatus {
	EstimateGiven = 1,
	AbandonedBeforePayment = 3,
	NotProvidedPAYEOrSARPermission = 4,
	NotCompletedEmployerDetails = 5,
	SubmitNextTaxYear = 6,
	ReverseSpouseClaimIncomplete = 7,
	SpouseClaimIncomplete = 8,
	SpouseClaimLegacy = 9,
	CompletePaymentSuccessful = 10,
	CompleteFullRecord = 11,
	CompleteSpouseClaim = 12,
	CompleteReverseSpouseClaim = 13,
	PaymentFailed = 20,
	ImportedFromLegacy = 30,
	SentTo3rdParty = 40,
	CancelledByCustomer = 50,
	CancelledWorksPartTime = 53,
	CancelledNothingToClaimFor = 54,
	CancelledClaimedBefore = 55,
	CancelledSelfAssessed = 56,
	CancelledNoTaxPaid = 57,
	CancelledEarningsTooLow = 58,
	CancelledNoEmploymentHistory = 59,
	CancelledDuplicateClaim = 60,
	CancelledDetailsRejected = 61,
	SpouseClaimNotValid = 70,
}
