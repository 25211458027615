import ButtonNavigation from "@/components/ButtonNavigation"
import { useEmailDidYouMean } from "@/composition/useEmailDidYouMean"
import { shallowRef } from "vue"
import { defineComponent, useInputValidation, type ReactiveComponent } from "vue-utils"
import SelectTitle from "./components/SelectTitle"
import TrustPilotDisplay from "./components/TrustPilotDisplay"

const NameStep: ReactiveComponent = () => {
	const emailInputRef = shallowRef<HTMLInputElement>()

	useInputValidation(emailInputRef, () => {
		if (App.CustomerClaim.Email !== App.CustomerClaim.emailConfirm) {
			return "Please ensure both emails match."
		}
		return true
	})

	useEmailDidYouMean(
		App.CustomerClaim.ClaimId,
		() => App.CustomerClaim.Email,
		(newEmail) => {
			//Set both email & email confirm - assumption is at this point validation has passed they are the same
			App.CustomerClaim.Email = newEmail
			App.CustomerClaim.emailConfirm = newEmail
		}
	)

	return () => (
		<>
			<div class="col-12 my-2">
				<SelectTitle title={App.CustomerClaim.Title} setTitle={(title) => (App.CustomerClaim.Title = title)} />
			</div>
			<div class="col-12 my-2 col-md-6">
				<input
					type="text"
					class="form-control"
					autocomplete="on"
					v-model={App.CustomerClaim.FirstName}
					placeholder="Your first name(s)"
					required
				/>
			</div>
			<div class="col-12 my-2 col-md-6">
				<input type="text" class="form-control" autocomplete="on" v-model={App.CustomerClaim.Surname} placeholder="Your surname" required />
			</div>
			<div class="col-12 my-2 col-md-6">
				<input type="email" class="form-control" v-model={App.CustomerClaim.Email} placeholder="Your email address" autocomplete="on" required />
			</div>
			<div class="col-12 my-2 col-md-6">
				<input
					type="email"
					ref={emailInputRef}
					class="form-control"
					v-model={App.CustomerClaim.emailConfirm}
					placeholder="Please repeat email address"
					autocomplete="on"
					required
				/>
			</div>
			{!suppressUBOptin ? (
				<div class="col-12 my-2">
					<small style="line-height: 1em;">This FREE tax rebate estimate is provided by One Time Refund Ltd.</small>
					<label class="custom-control custom-control-inline custom-radio">
						<input
							type="radio"
							name="optin"
							v-model={App.CustomerClaim.ThirdPtyPermission}
							id="inp_optin_yes"
							value="true"
							class="custom-control-input required"
							required
						/>

						<span class="custom-control-label">
							<strong>YES – I'd like to find out how much tax rebate I'm owed.</strong> I consent to receive electronic communications from
							Universal Benefits about my rebate and to inform me of money-saving offers and services.{" "}
							<strong>I understand that I can opt-out of these communications at any time.</strong>
						</span>
					</label>
					<label class="custom-control custom-control-inline custom-radio">
						<input
							type="radio"
							name="optin"
							v-model={App.CustomerClaim.ThirdPtyPermission}
							id="inp_optin_no"
							value="false"
							class="custom-control-input required"
							required
						/>

						<span class="custom-control-label">
							No. I don't want to be told about money-saving offers and other services from Universal Benefits but I'd still like to see how much
							rebate I'm owed.
						</span>
					</label>
				</div>
			) : (
				<input type="hidden" value={false} v-model={App.CustomerClaim.ThirdPtyPermission} />
			)}

			<ButtonNavigation />
			<div class="col-12 my-2">
				<div class="jumbotron inverse">
					<TrustPilotDisplay />
				</div>
			</div>
		</>
	)
}

export default defineComponent(NameStep)
