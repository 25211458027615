import { defineComponent, type ReactiveComponent } from "vue-utils"
import MarriageClaimLink from "./components/MarriageClaimLink"

const LackOfExpenses: ReactiveComponent = () => {
	return () => (
		<div class="col-12 my-1">
			<div class="jumbotron">
				<h2 class="xbold">Sorry - you don't qualify for a claim</h2>
				<p>
					Based on your employment history and lack of expenses we have calculated that you have nothing to claim for. I'm afraid this means we
					will be unable to process your claim.
				</p>
				{(App.CustomerClaim.Married ?? true) && (
					<>
						<p class="bold">You might be eligible for a Marriage Tax Rebate however - click the button below if you're married.</p>
						<MarriageClaimLink />
					</>
				)}
			</div>
		</div>
	)
}

export default defineComponent(LackOfExpenses)
