import type { Employer, EmploymentRecordDate, Nullable } from "@/types"

export function compareEmploymentDates(d1: EmploymentRecordDate, d2: EmploymentRecordDate): number {
	if (d1 === d2) return 0
	if (d1.Year < d2.Year || (d1.Year === d2.Year && d1.Month < d2.Month)) return -1
	if (d2.Year < d1.Year || (d2.Year === d1.Year && d2.Month < d1.Month)) return 1
	return 0
}

export function getLatestJob(records: Employer[]): Nullable<Employer> {
	if (records.length === 0) {
		return null
	}

	const sortedJobs = [...records].sort((r1, r2) => {
		return (r1.YearCommenced ?? 0) < (r2.YearCommenced ?? 0) ? -1 : 1
	})

	return sortedJobs[0]
}

export function calculateTotalYearsEmployed(records: Employer[]): number {
	const current = new Date().getFullYear()
	return current - Math.min(...records.map((x) => x.YearCommenced ?? current))
}
