import ButtonNavigation from "@/components/ButtonNavigation"
import RadioButton from "@/components/RadioButton"
import { pandemicYearIds } from "@/constants"
import type { Id, WorkFromHomeYear } from "@/types"
import { css } from "vite-css-in-js"
import { computed } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const tableStyles = css`
	width: 100%;

	td,
	th {
		padding: 0.5rem;
		vertical-align: middle;
	}

	thead th {
		font-weight: bold;
		text-align: center;
		text-transform: uppercase;
	}

	tbody tr {
		border-bottom: solid 2px #fff;
	}

	label {
		margin-right: 0.25rem;
	}
`

const WorkFromHomeStep: ReactiveComponent = () => {
	function taxYearName(taxYearId: Id) {
		return App.currentTaxYears?.find((ty) => ty.Value === taxYearId)?.Key ?? ""
	}

	const wfhYears = computed(() => {
		const years = (App.CustomerClaim.WorkFromHomeYears ?? []).filter((yr) => pandemicYearIds.includes(yr.Id))
		years.sort((y1, y2) => y2.Id - y1.Id)
		return years
	})

	function renderYear(taxYear: WorkFromHomeYear) {
		return (
			<tr key={taxYear.Id}>
				<td class="text-left">
					<span class="font-weight-bold">{`06/04/${taxYearName(taxYear.Id).split("/")[0].substring(2)}`}</span>
					&nbsp;to&nbsp;
					<span class="font-weight-bold">{`05/04/${taxYearName(taxYear.Id).split("/")[1]}`}</span>
				</td>
				<td class="text-center">
					<RadioButton
						name={`taxyearWFH${taxYear.Id}`}
						enabledValue={true}
						value={taxYear.Value}
						setValue={(v) => (taxYear.Value = v)}
						required
					/>
				</td>
				<td class="text-center">
					<RadioButton
						name={`taxyearWFH${taxYear.Id}`}
						enabledValue={false}
						value={taxYear.Value}
						setValue={(v) => (taxYear.Value = v)}
						required
					/>
				</td>
			</tr>
		)
	}

	return () => (
		<>
			<div class="col-12 my-2">
				<h3 class="py-2 border border-top-0 border-left-0 border-right-0">Working from home</h3>
				<p class="d-block my-2 alert alert-success">
					Did you have to work from home at all during the COVID Pandemic? <br />
					<strong>Years to 05/04/21 &amp; 05/04/22 ONLY</strong> you can claim for a full 52 weeks in the year, even if you only worked from home
					for 1 day.
				</p>
				<table class={tableStyles}>
					<thead>
						<tr>
							<th style={{ width: "40%" }} />
							<th style={{ width: "20%" }}>Yes</th>
							<th style={{ width: "20%" }}>No</th>
						</tr>
					</thead>
					<tbody>{!!App.CustomerClaim.WorkFromHomeYears && App.currentTaxYears.length > 0 && wfhYears.value.map(renderYear)}</tbody>
				</table>
			</div>
			<ButtonNavigation />
		</>
	)
}

export default defineComponent(WorkFromHomeStep)
