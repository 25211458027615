import { httpJsonRequest, RequestMethod } from "@/apis/httpService"
import { useLoading } from "@/composition/useLoading"
import moment from "moment"
import { createApp, reactive } from "vue"
import { defineGlobals } from "vue-utils"
import { TaxRebateVuePlugin } from "../TaxRebateVuePlugin"
import { getGlobal } from "../utils/globals"

interface AddressData {
	Address1: string
	Address2: string
	Address3: string
	County: string
	Postcode: string
}

/*BEGIN /claims/address/ logic*/
const UpdateAddressForm = reactive({
	Key: "",
	Finished: false,
	Address: getGlobal<AddressData | undefined>("UpdateAddressData"),
})

//initialize vue context
function vueInitUpdateAddressForm() {
	const { runAction } = useLoading()
	const app = createApp({
		data: () => ({ UpdateAddressForm }),
		watch: {},
		methods: {
			frontEndDateFormat(date: string) {
				return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY")
			},
			async saveAddress(e: Event) {
				const btn = $(e.target as Element)
				const key = btn.data("key") as string
				UpdateAddressForm.Key = key

				await runAction(updateAddressAndResendForms())
				UpdateAddressForm.Finished = true
			},
		},
	})

	app.use(new TaxRebateVuePlugin())
	app.mount("#UpdateAddress")

	return app
}

export function updateAddressFormInit() {
	if ($("#UpdateAddress").length) {
		vueInitUpdateAddressForm()
	}
}

async function updateAddressAndResendForms(): Promise<void> {
	const data = UpdateAddressForm.Address
	await httpJsonRequest(`/api/ui/address/${UpdateAddressForm.Key}/updateandresend`, data, RequestMethod.POST)
}

defineGlobals({ UpdateAddressForm })
