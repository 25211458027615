/**
 * Calculates the bigram similarity between two strings.
 *
 * @param {string} text - The first input string.
 * @param {string} pattern - The second input string.
 * @returns {number} The bigram similarity between the two strings (a value between 0 and 1).
 */
export function bigramSimilarity(text: string, pattern: string): number {
	text = text.replace(/\s+/g, "")
	pattern = pattern.replace(/\s+/g, "")

	if (text === pattern) return 1
	if (text.length < 2 || pattern.length < 2) return 0

	const firstBigrams = new Map<string, number>()
	for (let i = 0; i < text.length - 1; i++) {
		const bigram = text.substring(i, i + 2)
		const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram)! + 1 : 1

		firstBigrams.set(bigram, count)
	}

	let intersectionSize = 0
	for (let i = 0; i < pattern.length - 1; i++) {
		const bigram = pattern.substring(i, i + 2)
		const count = firstBigrams.has(bigram) ? firstBigrams.get(bigram)! : 0

		if (count > 0) {
			firstBigrams.set(bigram, count - 1)
			intersectionSize++
		}
	}

	const denominator = text.length + pattern.length - 2
	if (denominator === 0) return 0

	return (2.0 * intersectionSize) / denominator
}
