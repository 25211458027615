import { addressLookup, getAddress } from "@/apis"
import { showModalMessage } from "@/modals"
import type { AddressResult } from "@/types"
import { isPostcodeValid } from "@/utils/postcode"
import { shallowRef } from "vue"
import { useLoading } from "./useLoading"

export default function usePostcodeLookup() {
	const { runAction } = useLoading()
	const addresses = shallowRef<AddressResult[]>([])

	/**
	 * Attempts to find the matching address
	 */
	async function matchAddress(addressId: string): Promise<AddressResult | null> {
		if (addressId === "-1" || addressId === "") {
			return null
		}
		return await getAddress(addressId)
	}

	async function lookupPostcode(postcode: string, isUserAction = true) {
		//Remove all whitespace
		postcode = postcode.replace(/\s/g, "")

		if (isUserAction && !isPostcodeValid(postcode)) {
			void showModalMessage("Postcode search", "Please input a complete UK postcode.")
			throw new Error("Invalid postcode")
		}

		const addressesResult = await runAction(addressLookup(postcode))
		if (addressesResult.length === 0 && isUserAction) {
			void showModalMessage(
				"Postcode search",
				"No addresses could be found for the postcode you supplied. Please ensure you have provided a valid UK RESIDENTIAL Postcode."
			)
		}

		addresses.value = addressesResult
		return addressesResult
	}

	return {
		addresses,
		lookupPostcode,
		matchAddress,
	}
}
