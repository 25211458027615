import { defineComponent, type ReactiveComponent } from "vue-utils"

const MarriageClaimLink: ReactiveComponent = () => {
	return () => (
		<>
			<p>
				<a class="btn btn-success" href="/marriage/?married=1">
					<i class="fa fa-check"></i> Click here if you are married &gt;
				</a>
			</p>
		</>
	)
}

export default defineComponent(MarriageClaimLink)
