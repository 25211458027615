import { defineComponent, type ReactiveComponent } from "vue-utils"
import MarriageClaimLink from "./components/MarriageClaimLink"

const NoEmploymentHistory: ReactiveComponent = () => {
	return () => (
		<div class="col-12 my-1">
			<div class="jumbotron">
				<h2 class="xbold">Sorry - you don't qualify for a claim</h2>
				<p>We can only process employment-based rebates for those currently in a job.</p>
				<p class="bold">However, if you are married or in a civil partnership you might qualify for a marriage rebate.</p>
				<MarriageClaimLink />
			</div>
		</div>
	)
}

export default defineComponent(NoEmploymentHistory)
