import type { InputHTMLAttributes } from "vue"
import { defineComponent, inferProps, renderSlot, type ReactiveComponent } from "vue-utils"

interface Props {
	enabledValue: boolean
	value: boolean | null | undefined
	setValue(newValue: boolean): void
}

const RadioButton: ReactiveComponent<Props, InputHTMLAttributes> = (props, { attrs, slots }) => {
	return () => (
		<label class="custom-control custom-control-inline custom-radio">
			<input
				type="radio"
				value="false"
				class={["custom-control-input", attrs.required && "required"]}
				checked={props.value === props.enabledValue}
				onInput={() => props.setValue(props.enabledValue)}
				{...attrs}
			/>
			<span class="custom-control-label">{renderSlot(slots)}</span>
		</label>
	)
}

export default defineComponent(RadioButton, inferProps<Props>())
