import { showModalMessage } from "@/modals"
import { createLoadingHook, LoadingError, watchDebounce } from "vue-utils"

const { LoadingWrapper, useLoading, loading } = createLoadingHook({
	loadingComponent: () => null,
	showError: showModalMessage,
	coolInTimeMs: 100,
	defaultError: "Sorry, we could not contact the server. Please try again.",
})

watchDebounce(
	loading,
	() => {
		if (loading.value) {
			$("#waiting").fadeIn()
		} else {
			$("#waiting").fadeOut()
		}
	},
	125
)

export { LoadingWrapper, useLoading, LoadingError }
