import ButtonNavigation from "@/components/ButtonNavigation"
import { computed } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import EditSignature from "./components/EditSignature"

const SubscriptionPermissionStep: ReactiveComponent = () => {
	const enabledSubscriptions = computed(() => App.CustomerClaim.Subscriptions.filter((sub) => sub.Enabled))

	//TODO Make sure you can't submit without checking the boxes
	return () => (
		<>
			<div class="col-12 my-2">
				<h3 class="bold">Subscriptions - Permission</h3>
				{enabledSubscriptions.value.length === 0 ? (
					<p>You have not selected any available subscriptions or expenses.</p>
				) : (
					<>
						<p>
							You have indicated that you pay membership or subscription fees to the following organisations. In order to make a claim on your
							behalf, OTR needs to contact these organisations to confirm your membership costs. Please use the tick box below to indicate your
							permission for us to proceed with this.
						</p>
						{App.CustomerClaim.Subscriptions.filter((sub) => sub.Enabled).map((sub) => (
							<p class="bold" key={sub.OccupationSubscriptionId}>
								<i class="fa fa-check"></i> {sub.Name}
							</p>
						))}
					</>
				)}
			</div>
			<hr />
			<label class="custom-control custom-control-inline custom-checkbox">
				<input
					type="checkbox"
					class="custom-control-input required"
					v-model={App.CustomerClaim.SubscriptionsPermission}
					name="subs_permission"
					id="inp_subs_permission"
					required
				/>

				<span class="custom-control-label bold">
					I give permission for OTR to contact the organisations listed above for details of my subscription costs for the purpose of obtaining a
					tax rebate.
				</span>
			</label>
			<EditSignature setSignatureSVG={(svg) => (App.CustomerClaim.SubscriptionsSignatureSVG = svg)} />
			<ButtonNavigation />
		</>
	)
}

export default defineComponent(SubscriptionPermissionStep)
