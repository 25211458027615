import type { ClaimStepData } from "@/steps"
import { ref, shallowRef } from "vue"

/*
	"CurrentStep" variable is defined on the global object as a property so that getting and settings its value
	hooks into Vue's reactivity system, allowing updating of the page when set
*/
const currentStepRef = shallowRef<ClaimStepData>()
Object.defineProperty(globalThis, "CurrentStep", {
	get() {
		return currentStepRef.value
	},
	set(value) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
		currentStepRef.value = value
	},
})

export const ignoreNavigation = ref(false)
