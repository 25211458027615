/* eslint-disable jsx-a11y/label-has-associated-control */
import { occupationSearch } from "@/apis"
import ButtonNavigation from "@/components/ButtonNavigation"
import createSearchSelect from "@/components/SearchSelect"
import type { Employer, OccupationSearch } from "@/types"
import { computed, ref, toRef } from "vue"
import { defineComponent, watchDebounce, type ReactiveComponent } from "vue-utils"
import IndustrySelect from "./employment/components/IndustrySelect"
import OccupationSelect from "./employment/components/OccupationSelect"

const JobSelect = createSearchSelect<OccupationSearch>()

const OccupationStep: ReactiveComponent = () => {
	const showJobs = ref<boolean>(!App.CustomerClaim.Employers.some((emp) => !emp.IndustryId))
	const readOnly = ref<boolean>(!App.CustomerClaim.Employers.some((emp) => !emp.IndustryId))
	const occupationSearchResults = ref<OccupationSearch[]>([]) ///TODO - size this correctly...
	const unknownJob = ref(false)
	const activeEmployerIndex = ref(0)

	const activeEmployer = computed(() => {
		return App.CustomerClaim.Employers[activeEmployerIndex.value]
	})
	const allEmployersPopulated = computed(() => {
		return App.CustomerClaim.Employers.length <= 1 || !App.CustomerClaim.Employers.some((employer) => employer.JobTitle === "")
	})
	const showButtons = computed(() => {
		return activeEmployer.value.JobTitle !== ""
	})

	async function runOccupationSearch(search: string) {
		const results = search.length === 0 ? [] : await occupationSearch(search)
		unknownJob.value = unknownJob.value || results.length === 0
		occupationSearchResults.value = results
	}
	watchDebounce(activeEmployerIndex, () => {
		occupationSearchResults.value = []
		watchDebounce(toRef(activeEmployer.value, "JobTitle"), () => {
			void runOccupationSearch(App.CustomerClaim.Employers[activeEmployerIndex.value].JobTitle)
		})
	})
	watchDebounce(toRef(activeEmployer.value, "JobTitle"), () => {
		void runOccupationSearch(App.CustomerClaim.Employers[activeEmployerIndex.value].JobTitle)
	})

	function onSelectJob(job: OccupationSearch) {
		const newData: Partial<Employer> = {
			IndustryId: job.IndustryId,
			OccupationId: job.OccupationId,
			JobTitle: job.Name,
		}
		Object.assign(App.CustomerClaim.Employers[activeEmployerIndex.value], newData)
	}

	function updateJobSearch(search: string) {
		const newData: Partial<Employer> = {
			JobTitle: search,
			OccupationId: null,
			IndustryId: null,
		}
		Object.assign(App.CustomerClaim.Employers[activeEmployerIndex.value], newData)
	}

	function nextEmployer() {
		activeEmployerIndex.value += 1
	}

	function editEmployer(index: number) {
		activeEmployerIndex.value = index
		readOnly.value = false
	}

	return () => (
		<>
			<div class="col-12 my-2 text-center">
				<h3 class="bold">Your Jobs</h3>
			</div>
			{showJobs.value && (
				<div class="col col-md-10 col-lg-8 mx-auto mb-4">
					<div class="row">
						{App.CustomerClaim.Employers.filter((f) => f.IndustryId).map((emp, index) => (
							<button
								key={index}
								class={index === activeEmployerIndex.value && !readOnly.value ? "col m-2 alert alert-warning" : "col m-2 alert alert-info"}
								onClick={() => {
									editEmployer(index)
								}}
							>
								<span style="font-size: .9rem" class={index === activeEmployerIndex.value && !readOnly.value ? "bold" : ""}>
									{emp.EmployerName !== "" ? `${emp.JobTitle} at ${emp.EmployerName}` : emp.JobTitle}
									{emp.YearCommenced ? ` starting in ${emp.YearCommenced}` : ""}
								</span>
							</button>
						))}
					</div>
				</div>
			)}
			{!readOnly.value && (
				<div class="col col-md-10 col-lg-8 mx-auto">
					<div class="row">
						<div class="col-12">
							<div class="row">
								<div class="col-12 claim-label">
									{activeEmployer.value.PAYEReference === "" && <p class="bold">What's your current job?</p>}
									{activeEmployer.value.PAYEReference !== "" && (
										<p class="bold">While you were working at {activeEmployer.value.EmployerName}, what was your job?</p>
									)}
								</div>
								<div class="col-12">
									<JobSelect
										style={{ flexGrow: 1 }}
										inputProps={{ class: "form-control", placeholder: "Search for your job", required: true }}
										getKey={(o) => o.Id.toString()}
										getText={(o) => o.Name}
										options={occupationSearchResults.value}
										searchTerm={activeEmployer.value.JobTitle}
										setSearch={(term) => updateJobSearch(term)}
										setOption={(job) => onSelectJob(job)}
									/>
								</div>
								{unknownJob.value && (
									<div class="col-12 mt-3">
										<IndustrySelect
											industryId={activeEmployer.value.IndustryId ?? -1}
											setIndustryId={(id) => {
												activeEmployer.value.IndustryId = id
												activeEmployer.value.OccupationId = -1
											}}
										/>
									</div>
								)}
								{unknownJob.value && activeEmployer.value.IndustryId !== null && activeEmployer.value.IndustryId > 0 && (
									<div class="col-12 mt-3">
										<OccupationSelect
											industryId={undefined}
											occupationId={activeEmployer.value.OccupationId ?? -1}
											setOccupationId={(id) => (activeEmployer.value.OccupationId = id)}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			)}
			<div class="col-12 align-middle mt-2 text-center">
				{showButtons.value && (
					<>
						{!allEmployersPopulated.value && (
							<button class="btn btn-secondary" onClick={nextEmployer}>
								Next job (at {App.CustomerClaim.Employers[activeEmployerIndex.value + 1].EmployerName}) <i class="fa fa-arrow-right" />
							</button>
						)}
						{allEmployersPopulated.value && <ButtonNavigation />}
					</>
				)}
			</div>
		</>
	)
}

export default defineComponent(OccupationStep)
