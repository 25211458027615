import { defineComponent, type ReactiveComponent } from "vue-utils"

const RequireSelfAssessment: ReactiveComponent = () => {
	return () => (
		<div class="col-12 my-1">
			<div class="jumbotron">
				<h2 class="xbold">Self Assessment Taxpayers</h2>
				<p>
					I'm sorry but this claim process is for taxpayers with simple tax affairs. This means you have a regular
					employer who pays you a salary and deducts tax at source.
				</p>
				<p>
					If you are required to submit a self assessment tax return, usually by 31st January deadline, in any of the
					years of the claim you can't use this service.
				</p>
			</div>
		</div>
	)
}

export default defineComponent(RequireSelfAssessment)
