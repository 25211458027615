import { insertQualifyingCheck } from "@/apis"
import ClaimSteps from "@/steps"
import { ClaimStatus } from "@/types"
import type { QualifyingCheck } from "."

export const NothingToClaimForCheck: QualifyingCheck = ({ claim }) => {
	// Status set by server, calculated that there is nothing to claim for - no uniform, subs or expenses - so claim ends
	if (claim.ClaimStatus !== ClaimStatus.CancelledNothingToClaimFor) {
		return true
	}

	void insertQualifyingCheck("Is there anything to claim for?", "no", false, claim.ClaimId, "ANYCL")
	return ClaimSteps.LackOfExpenses
}
