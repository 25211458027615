import { ref, toRef, watch, type InputHTMLAttributes } from "vue"
import { defineComponent, inferProps, useInputValidation, type ReactiveComponent } from "vue-utils"

interface Props {
	date: Date | null | string
	setDate(date: string): void
}

const inputRegex = /^((0[1-9])|([1-2]\d)|(3[0-1]))\/((0[1-9])|10|11|12)\/((19|20)\d{2})$/

function formatDate(date: Date): string {
	if (!Number.isFinite(date.valueOf())) {
		return ""
	}
	return date.toLocaleDateString("en-GB", {
		day: "2-digit",
		month: "2-digit",
		year: "numeric",
	})
}
function formatDateInputString(date: Props["date"]): string {
	if (!date) {
		return ""
	}
	if (date instanceof Date) {
		return formatDate(date)
	}
	const parsedDate = new Date(date)
	if (!Number.isFinite(parsedDate.valueOf())) {
		return date
	}
	return formatDate(parsedDate)
}

function tryParseDate(str: string): Date | null {
	if (!inputRegex.test(str)) {
		return null
	}
	const parts = str.split("/").map((part) => Number.parseInt(part))
	const date = new Date(parts[2], parts[1] - 1, parts[0])
	if (date.getDate() !== parts[0] || date.getMonth() !== parts[1] - 1 || date.getFullYear() !== parts[2]) {
		return null
	}
	return date
}

const TextualDateInput: ReactiveComponent<Props, Omit<InputHTMLAttributes, "value">> = (props, { attrs }) => {
	const inputRef = ref<HTMLInputElement>()

	const dateStr = ref(formatDateInputString(props.date))
	watch(toRef(props, "date"), (date) => {
		dateStr.value = formatDateInputString(date)
	})

	watch(dateStr, (str) => {
		const date = tryParseDate(str)
		if (date) {
			props.setDate(date.toISOString())
		}
	})

	useInputValidation(inputRef, () => {
		if (dateStr.value.length > 0 && !tryParseDate(dateStr.value)) {
			return "Please enter a valid date"
		}
		return true
	})

	return () => <input ref={inputRef} type="text" v-mask="##/##/####" v-model={dateStr.value} {...attrs} />
}

export default defineComponent(TextualDateInput, inferProps<Props>())
