import SignaturePad from "signature_pad"
import { onMounted, ref, toRef, watch } from "vue"
import { defineComponent, inferProps, useCustomValidation, useWindowListener, type ReactiveComponent } from "vue-utils"

interface Props {
	setSignatureSVG(svg: string): void
	setSignaturePoints?(points: string): void
	penColor?: string
}

const EditSignature: ReactiveComponent<Props> = (props) => {
	const canvasRef = ref<HTMLCanvasElement>()
	let signaturePad: SignaturePad | null = null

	useCustomValidation(() => {
		if (!signaturePad || signaturePad.isEmpty() || signaturePad.toDataURL("image/svg+xml").length < 800) {
			return "Please provide a valid signature for your forms."
		}
		return true
	})

	onMounted(() => {
		if (!canvasRef.value) {
			throw new Error("No canvas available")
		}
		resizeSignature()

		signaturePad = new SignaturePad(canvasRef.value, {
			penColor: props.penColor ?? "rgb(0,0,100)",
		})
		signaturePad.addEventListener("endStroke", updateSignature)

		updateSignature()
	})
	watch(toRef(props, "penColor"), (penColor) => {
		if (signaturePad) {
			signaturePad.penColor = penColor ?? "rgb(0,0,100)"
		}
	})
	useWindowListener("resize", () => {
		resizeSignature()

		//TODO Why clear the signature on resize?
		signaturePad?.clear()
		updateSignature()
	})

	function resizeSignature() {
		const element = canvasRef.value
		const ratio = Math.max(window.devicePixelRatio || 1, 1)
		if (element) {
			element.width = element.offsetWidth * ratio
			element.height = element.offsetHeight * ratio
			element.getContext("2d")?.scale(ratio, ratio)
		}
	}

	function updateSignature() {
		if (signaturePad) {
			props.setSignatureSVG(signaturePad.toDataURL("image/svg+xml"))
			if (props.setSignaturePoints) {
				props.setSignaturePoints(JSON.stringify(signaturePad.toData()))
			}
		}
	}

	function clearSignature() {
		signaturePad?.clear()
		updateSignature()
	}

	return () => (
		<div class="col-12 my-2 signature-container">
			<div class="siglabel" id="siglabel">
				Please sign in the box below using your finger or your mouse
			</div>
			<div class="sig" id="sig">
				<canvas ref={canvasRef} id="signature" class="signature" />
			</div>

			<div class="sigcontrols" id="sigcontrols">
				<button class="clear btn btn-secondary" onClick={clearSignature}>
					<i class="fa fa-ban" /> clear signature
				</button>
			</div>
		</div>
	)
}

export default defineComponent(EditSignature, inferProps<Props>())
