import { useGoToNext } from "@/composition/useNext"
import { css } from "vite-css-in-js"
import { ref, shallowRef, toRef, watch, type InputHTMLAttributes } from "vue"
import { defineComponent, inferProps, useInputValidation, type ReactiveComponent } from "vue-utils"

interface Props {
	date: Date | null | string
	label: string | null
	setDate(date: string): void
}

const inputRegex = /^((0[1-9])|([1-2]\d)|(3[0-1]))\/((0[1-9])|10|11|12)\/((19|20)\d{2})$/

const inputStyles = css`
	//Firefox
	-moz-appearance: textfield;

	//Chromium
	:-webkit-outer-spin-button,
	:-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	text-align: center;
`

function formatDate(date: Date): string {
	if (!Number.isFinite(date.valueOf())) {
		return ""
	}
	return date.toLocaleDateString("en-GB", {
		day: "2-digit",
		month: "2-digit",
		year: "numeric",
	})
}

function getDayStr(date: Props["date"]): string {
	return formatDateInputString(date).split("/")[0]
}
function getMonthStr(date: Props["date"]): string {
	return formatDateInputString(date).split("/")[1]
}
function getYearStr(date: Props["date"]): string {
	return formatDateInputString(date).split("/")[2]
}

function formatDateInputString(date: Props["date"]): string {
	if (!date) {
		return ""
	}
	if (date instanceof Date) {
		return formatDate(date)
	}
	const parsedDate = new Date(date)
	if (!Number.isFinite(parsedDate.valueOf())) {
		return date
	}
	return formatDate(parsedDate)
}

function tryParseDate(str: string): Date | null {
	if (!inputRegex.test(str)) {
		return null
	}
	const parts = str.split("/").map((part) => Number.parseInt(part))
	const date = new Date(parts[2], parts[1] - 1, parts[0])
	if (date.getDate() !== parts[0] || date.getMonth() !== parts[1] - 1 || date.getFullYear() !== parts[2]) {
		return null
	}
	return date
}

const SplitDateInput: ReactiveComponent<Props, Omit<InputHTMLAttributes, "value">> = (props, { attrs }) => {
	const inputRef = shallowRef<HTMLInputElement>()

	const dayStr = ref(getDayStr(props.date))
	const monthStr = ref(getMonthStr(props.date))
	const yearStr = ref(getYearStr(props.date))

	let dateStr = `${dayStr.value}/${monthStr.value}/${yearStr.value}`
	watch(toRef(props, "date"), (date) => (dateStr = formatDateInputString(date)))

	watch([dayStr, monthStr, yearStr], () => {
		if (dayStr.value && monthStr && yearStr && yearStr.value && yearStr.value.length === 4) {
			const s = `${dayStr.value}/${monthStr.value}/${yearStr.value}`
			let date = tryParseDate(s)
			if (date) {
				const offset = date.getTimezoneOffset()
				date = new Date(date.getTime() - offset * 60 * 1000)
				props.setDate(date.toISOString().split("T")[0])
			} else {
				props.setDate("")
			}
		}
	})

	useInputValidation(inputRef, () => {
		if (dateStr.length > 0 && !tryParseDate(dateStr)) {
			return "Please enter a valid date"
		}
		return true
	})

	function checkTwoDigits(value: string) {
		return /[0-9]{2}/.test(value)
	}

	return () => (
		<div class="input-group col-12" {...attrs}>
			<div class="input-group-prepend">
				<span class="input-group-text small">{props.label}</span>
			</div>
			<input
				ref={inputRef}
				onInput={useGoToNext(checkTwoDigits)}
				class={["form-control", inputStyles]}
				placeholder="day"
				type="text"
				inputmode="numeric"
				v-mask="##"
				v-model={dayStr.value}
				required={attrs.required}
			/>
			<input
				onInput={useGoToNext(checkTwoDigits)}
				class={["form-control", inputStyles]}
				placeholder="month"
				type="text"
				inputmode="numeric"
				v-mask="##"
				v-model={monthStr.value}
				required={attrs.required}
			/>
			<input
				class={["form-control", inputStyles]}
				placeholder="year"
				type="text"
				inputmode="numeric"
				v-mask="####"
				v-model={yearStr.value}
				required={attrs.required}
			/>
		</div>
	)
}

export default defineComponent(SplitDateInput, inferProps<Props>())
