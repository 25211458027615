import { defineComponent, type ReactiveComponent } from "vue-utils"
import MarriageClaimLink from "./components/MarriageClaimLink"
import UniformBenefits from "./components/UniformBenefits"

const AlreadyClaimed: ReactiveComponent = () => {
	return () => (
		<>
			<div class="col-12 mb-1 text-center">
				<h3 class="bold">Your Claim</h3>
			</div>
			<div class="col-12 mb-1">
				<p>
					I'm sorry this is a one off process. When you claim the first time any relief is added to your tax code which means you continue to
					benefit from it as long as you continue your job.
				</p>
				<p>
					Because you have stated that you have previously made a claim before through us, with HMRC directly or with another company we cannot
					continue with your new claim.
				</p>
				{(App.CustomerClaim.Married ?? true) && (
					<>
						<p class="bold">You could still qualify for a Marriage Tax Rebate however. Find out by click the link below if you're married.</p>
						<MarriageClaimLink />
					</>
				)}
				<UniformBenefits />
			</div>
		</>
	)
}

export default defineComponent(AlreadyClaimed)
