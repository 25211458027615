/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { css } from "vite-css-in-js"
import { ref, type HTMLAttributes, type InputHTMLAttributes } from "vue"
import { defineComponent, inferProps, useOnInput, type Component, type ReactiveComponent } from "vue-utils"

interface Props<T> {
	searchTerm: string
	setSearch(term: string): void

	getKey(option: T): string
	getText(option: T): string

	setOption(option: T): void

	options: T[]

	inputProps?: InputHTMLAttributes

	placeholder?: string
	disabled?: boolean
}

const selectStyles = css`
	position: absolute;
	z-index: 2;
	left: 0;
	right: 0;
	top: 100%;
	width: 100%;
	max-height: 50vh;
	overflow: auto;

	list-style: none;
	margin: 0;
	padding: 0;
	text-align: left;

	background-color: white;
	border: 1px solid rgba(0, 0, 0, 0.25);
	border-radius: 0.5rem;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	border-top: none;

	& > li {
		color: initial;
		margin: 0;
		padding: 0.35rem 0.5rem;
		font-size: 0.95rem;
		transition: initial;

		&[data-hover="true"] {
			color: white;
			background-color: #f36d21;
			cursor: pointer;
		}
	}
`

const createSearchSelect = <T,>(): Component<Props<T>, HTMLAttributes> => {
	const SearchSelect: ReactiveComponent<Props<T>, HTMLAttributes> = (props, { attrs }) => {
		const isOpen = ref(false)
		const hoveredItem = ref<string | null>(null)
		const listRef = ref<HTMLElement>()

		function handleArrowNavigation(e: KeyboardEvent) {
			if (!isOpen.value || (e.key !== "ArrowDown" && e.key !== "ArrowUp")) {
				return
			}
			const index = props.options.findIndex((option) => props.getKey(option) === hoveredItem.value)
			let newIndex = e.key === "ArrowDown" ? index + 1 : index - 1
			if (newIndex < 0) {
				newIndex = props.options.length - 1
			} else if (newIndex >= props.options.length) {
				newIndex = 0
			}

			if (newIndex >= 0 && newIndex < props.options.length) {
				hoveredItem.value = props.getKey(props.options[newIndex])

				const container = listRef.value
				if (container && container.children.length >= newIndex) {
					const child = container.children[newIndex]
					if (
						child instanceof HTMLLIElement &&
						child.dataset.key === hoveredItem.value &&
						(child.offsetTop + child.clientHeight >= container.clientHeight + container.scrollTop || child.offsetTop <= container.scrollTop)
					) {
						container.scrollTop = child.offsetTop - container.clientHeight + child.clientHeight
					}
				}
			}
			e.preventDefault()
		}

		function inputKeyUp(e: KeyboardEvent) {
			if (e.key !== "Enter") {
				return
			}
			if (hoveredItem.value === null) {
				return
			}
			const option = props.options.find((option) => props.getKey(option) === hoveredItem.value)
			if (option) {
				handleOptionClick(option)
			}
		}

		function handleOptionClick(option: T) {
			props.setSearch(props.getText(option))
			props.setOption(option)

			isOpen.value = false
			hoveredItem.value = null
		}

		return () => (
			<div {...attrs} style={{ position: "relative" }}>
				<input
					type="text"
					placeholder={props.placeholder}
					value={props.searchTerm}
					onInput={useOnInput(props.setSearch)}
					onKeydown={handleArrowNavigation}
					onKeyup={inputKeyUp}
					disabled={props.disabled}
					onFocus={() => (isOpen.value = true)}
					onBlur={() => (isOpen.value = false)}
					{...(props.inputProps ?? {})}
				/>
				{isOpen.value && (
					<ul ref={listRef} class={selectStyles}>
						{props.options.map((option) => (
							<li
								key={props.getKey(option)}
								data-key={props.getKey(option)}
								data-hover={String(hoveredItem.value === props.getKey(option))}
								onMouseenter={() => (hoveredItem.value = props.getKey(option))}
								onMousedown={(e) => e.button === 0 && handleOptionClick(option)}
							>
								{props.getText(option)}
							</li>
						))}
					</ul>
				)}
			</div>
		)
	}

	return defineComponent(SearchSelect, inferProps<Props<T>>())
}

export default createSearchSelect
