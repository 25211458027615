import ButtonNavigation from "@/components/ButtonNavigation"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import LeaveAReview from "./components/LeaveAReview"
import MarriageClaimLink from "./components/MarriageClaimLink"
import UniformBenefits from "./components/UniformBenefits"

const NoTaxPaid: ReactiveComponent = () => {
	return () => (
		<>
			<div class="col-12 mb-1 text-center">
				<h3 class="bold">Your Claim</h3>
			</div>
			<div class="col-12 mb-1">
				{App.CustomerClaim.Married && App.CustomerClaim.SpouseEmployed ? (
					<>
						<p>Thanks for your claim. We'll send you a paper claim form within 2 weeks to complete your claim.</p>
						<LeaveAReview />
					</>
				) : (
					<>
						<p>
							Thanks for your claim. We need to perform some basic eligibility checks to see if you qualify for a refund. We will email you back
							within 48 hours.
						</p>

						{(App.CustomerClaim.Married ?? true) && (
							<>
								<p class="bold">You might be eligible for a Marriage Tax Rebate - click the button below if you're married</p>
								<MarriageClaimLink />
							</>
						)}
						<LeaveAReview />
					</>
				)}
				<UniformBenefits />
			</div>

			<ButtonNavigation nextDisabled />
		</>
	)
}

export default defineComponent(NoTaxPaid)
