import ButtonNavigation from "@/components/ButtonNavigation"
import { defineComponent, type ReactiveComponent } from "vue-utils"
import SignatureDetails from "./SignatureDetails"

const SignatureStep: ReactiveComponent = () => {
	return () => (
		<>
			<SignatureDetails />
			<ButtonNavigation backText="I Don't Want A Tax Rebate" nextText="Submit my claim to HMRC now" />
		</>
	)
}

export default defineComponent(SignatureStep)
