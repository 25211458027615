import { defineGlobals } from "vue-utils"

export function animateFigures() {
	$(".countup").each(function () {
		const val = Math.ceil(Number.parseFloat(String($(this).data("value"))))
		const text = $(this).text()

		if (testMode) console.log(`detected count up tag with max value of: ${val}`)

		if (text === "-") {
			$(this).text(val.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,"))
			$(this)
				.prop("Counter", 0)
				.animate(
					{
						Counter: val,
					},
					{
						duration: 2000,
						easing: "swing",
						step(now) {
							$(this).text(
								Math.ceil(now)
									.toString()
									.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
							)
						},
					}
				)
		}
	})
}

defineGlobals({ animateFigures })
