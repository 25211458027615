import { insertQualifyingCheck } from "@/apis"
import { selectableEmploymentYears } from "@/constants"
import ClaimSteps, { ClaimType } from "@/steps"
import { ClaimStatus } from "@/types"
import type { QualifyingCheck } from "."

export const NewTaxPayerCheck: QualifyingCheck = ({ claim }) => {
	//Not been paying tax long enough? - go to step defined above. Cannot process claim.
	if (CurrentStep.type !== ClaimType.Standard || CurrentStep.step < ClaimSteps.Employer.step) {
		return true
	}

	if (claim.Employers.length === 0) {
		claim.ClaimStatus = ClaimStatus.CancelledNoEmploymentHistory
		void insertQualifyingCheck(
			`Do you have any employment history in the past ${selectableEmploymentYears} years?`,
			"no",
			false,
			claim.ClaimId,
			"TAX1"
		)
		return ClaimSteps.NoEmploymentHistory
	}

	void insertQualifyingCheck(
		`Do you have any employment history in the past ${selectableEmploymentYears} years?`,
		"yes",
		true,
		claim.ClaimId,
		"TAX1"
	)
	return true
}
