import ButtonNavigation from "@/components/ButtonNavigation"
import RadioButtons from "@/components/RadioButtons"
import { computed } from "vue"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const BasicInformationStep: ReactiveComponent = () => {
	const canMoveForward = computed(
		() =>
			App.MODClaim.CompletedPhaseOneTraining !== false &&
			App.MODClaim.PostedToBaseTwoYearsOrLess !== false &&
			App.MODClaim.UseOwnVehicle !== false
	)

	return () => (
		<>
			<div class="col-12 my-1">
				<div class="jumbotron">
					<h2>MOD Tax Rebate Claims</h2>
					<p class="bold">
						You could be owed a £2,500 tax refund for your travel expenses. Bases outside the UK are included, and you might also be able to
						claim for Mess Dress.
					</p>
					<p>
						This is a complex claim and we've heard reports of some claims being rejected when submitted by inexperienced advisors. For that
						reason we would like to refer you to our expert partners who have obtained confirmation on the validity of claims both from HMRC and
						the MOD so you can have peace of mind that your refund is yours to keep when you receive it.
					</p>
					<p>If you complete the form below our partners will be in touch shortly about your free without obligation assessment.</p>
				</div>
			</div>
			<div class="col-12 mb-2 mt-4 text-center">
				<RadioButtons
					legend="Have you completed Phase 1 Training?"
					name="mod_phase1"
					id="inp_mod_phase1_no"
					value={App.MODClaim.CompletedPhaseOneTraining}
					setValue={(value) => (App.MODClaim.CompletedPhaseOneTraining = value)}
					required
				/>
			</div>
			<div class="col-12 my-2 text-center">
				<RadioButtons
					legend="Have you been posted to a base for 2 years or less? (Excluding tours)"
					name="mod_2years"
					id="inp_mod_2years_no"
					value={App.MODClaim.PostedToBaseTwoYearsOrLess}
					setValue={(value) => (App.MODClaim.PostedToBaseTwoYearsOrLess = value)}
					required
				/>
			</div>
			<div class="col-12 my-2 text-center">
				<RadioButtons
					legend="Did you use your own vehicle or public transport to get to those bases?"
					name="mod_ownvehicle"
					id="inp_mod_ownvehicle_no"
					value={App.MODClaim.UseOwnVehicle}
					setValue={(value) => (App.MODClaim.UseOwnVehicle = value)}
					required
				/>
			</div>
			{!canMoveForward.value && (
				<div class="col-12 my-2 text-center">
					<p class="badge badge-pill badge-danger p-3">
						<i class="fa fa-warning" />
						Based on the information you provided above, you won't be able to make a claim at this time.
					</p>
				</div>
			)}

			<ButtonNavigation hideBack nextDisabled={!canMoveForward.value} />
		</>
	)
}

export default defineComponent(BasicInformationStep)
