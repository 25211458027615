import { renderSlot, type InputHTMLAttributes } from "vue"
import { defineComponent, inferProps, type ReactiveComponent } from "vue-utils"
import RadioButton from "./RadioButton"

interface Props {
	name: string
	value: boolean | null | undefined
	setValue?: (newValue: boolean) => void
	required?: boolean
	readonly?: boolean
	legend?: string

	/**
	 * If true, render the yes option before the no option.
	 * @default false
	 */
	reverse?: boolean
}

const RadioButtons: ReactiveComponent<Props, InputHTMLAttributes> = (props, { emit, attrs, slots }) => {
	function setValue(value: boolean) {
		emit("input", value)
		props.setValue?.(value)
	}

	function renderNo() {
		return (
			<RadioButton
				key="no"
				name={props.name}
				enabledValue={false}
				value={props.value}
				setValue={setValue}
				required={props.required}
				disabled={props.readonly}
				{...attrs}
			>
				No
			</RadioButton>
		)
	}

	function renderYes() {
		return (
			<RadioButton
				key="yes"
				name={props.name}
				enabledValue={true}
				value={props.value}
				setValue={setValue}
				required={props.required}
				disabled={props.readonly}
				{...attrs}
			>
				Yes
			</RadioButton>
		)
	}

	return () => (
		<fieldset class="radio-buttons">
			{(props.legend || "default" in slots) && <legend>{props.legend ? props.legend : renderSlot(slots, "default")}</legend>}
			<div>
				{props.reverse ? renderYes() : renderNo()}
				{props.reverse ? renderNo() : renderYes()}
			</div>
		</fieldset>
	)
}

export default defineComponent(RadioButtons, inferProps<Props>())
