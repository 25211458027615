import ButtonNavigation from "@/components/ButtonNavigation"
import { useEmailDidYouMean } from "@/composition/useEmailDidYouMean"
import { validateInternationalMobile, validateUKMobile } from "@/utils/validation"
import { ref } from "vue"
import { defineComponent, useInputValidation, type ReactiveComponent } from "vue-utils"
import AddressInput from "../standard/components/AddressInput"
import SelectTitle from "../standard/components/SelectTitle"

type TelType = "uk" | "intl"

const AboutYouStep: ReactiveComponent = () => {
	//copy claim details to MOD Claim if needed
	if (App.MODClaim.Title === "na") {
		App.MODClaim.CustomerId = App.CustomerClaim.CustomerId
		App.MODClaim.ClaimId = App.CustomerClaim.ClaimId
		App.MODClaim.Title = App.CustomerClaim.Title
		App.MODClaim.FirstName = App.CustomerClaim.FirstName
		App.MODClaim.Surname = App.CustomerClaim.Surname
		App.MODClaim.EmailAddress = App.CustomerClaim.Email
		App.MODClaim.BrandId = App.CustomerClaim.BrandId
		App.MODClaim.Postcode = App.CustomerClaim.Postcode
		App.MODClaim.Address1 = App.CustomerClaim.Address1
		App.MODClaim.Address2 = App.CustomerClaim.Address2
		App.MODClaim.Address3 = App.CustomerClaim.Address3
		App.MODClaim.County = App.CustomerClaim.County
		App.MODClaim.MobileNumber = App.CustomerClaim.Phone
		App.CustomerClaim.ClaimStatus = 40 //set main claim object to 'Third Pty' status
	}

	const telRef = ref<HTMLInputElement>()
	const telType = ref<TelType>(App.MODClaim.MobileNumber === "" || validateUKMobile(App.MODClaim.MobileNumber) ? "uk" : "intl")

	useInputValidation(telRef, () => {
		if (telType.value === "uk") {
			if (App.MODClaim.MobileNumber === "" || !validateUKMobile(App.MODClaim.MobileNumber)) {
				return "Please provide a valid UK phone number"
			}
		} else if (App.MODClaim.MobileNumber === "" || !validateInternationalMobile(App.MODClaim.MobileNumber)) {
			return "Please provide a valid phone number"
		}
		return true
	})

	useEmailDidYouMean(
		App.CustomerClaim.ClaimId,
		() => App.MODClaim.EmailAddress,
		(newEmail) => (App.MODClaim.EmailAddress = newEmail)
	)

	return () => (
		<div class="row">
			<div class="col-12 mb-2">
				<h3>
					<span class="bold">MOD Claim </span>| About you
				</h3>
			</div>
			<div class="col-12 my-2 col-md-6">
				<SelectTitle title={App.MODClaim.Title} setTitle={(title) => (App.MODClaim.Title = title)} />
			</div>
			<div class="col-12 my-2 col-md-6">
				<input type="text" class="form-control" v-model={App.MODClaim.FirstName} placeholder="Your first name(s)" required />
			</div>
			<div class="col-12 my-2 col-md-6">
				<input type="text" class="form-control" v-model={App.MODClaim.Surname} placeholder="Your surname" required />
			</div>
			<div class="col-12 my-2 col-md-6">
				<input type="email" class="form-control" v-model={App.MODClaim.EmailAddress} placeholder="Your email address" required />
			</div>
			<div class="col-12 my-2 col-md-12">
				<div style="display: flex;">
					<select class="form-control" v-model={telType.value} style="width: 6rem;">
						<option value="uk">UK</option>
						<option value="intl">Intl</option>
					</select>
					<input
						ref={telRef}
						type="tel"
						v-model={App.MODClaim.MobileNumber}
						class="form-control"
						id="inp_phone_23"
						v-mask={telType.value === "uk" ? "##### ######" : undefined}
						placeholder="Mobile phone number"
						required
					/>
				</div>
				{/* <input ref={telRef} type="text" class="form-control" v-model={App.MODClaim.MobileNumber} placeholder="Your mobile number" required /> */}
			</div>

			<AddressInput
				address={App.MODClaim}
				updateAddress={(newAddress) => {
					Object.assign(App.CustomerClaim, newAddress)
					Object.assign(App.MODClaim, newAddress)
				}}
			/>

			<div class="col-12 my-2">
				<textarea class="form-control" rows="6" v-model={App.MODClaim.AdditionalInfo} placeholder="Any additional info..."></textarea>
			</div>

			<ButtonNavigation />
		</div>
	)
}

export default defineComponent(AboutYouStep)
