/* eslint-disable @typescript-eslint/no-misused-promises */
import moment from "moment"
import { addressLookup, getAddress } from "../apis"
import { fixPostcode } from "../utils/postcode"
import { showModalMessage } from "@/modals"
import { createApp, reactive, watch } from "vue"
import { TaxRebateVuePlugin } from "../TaxRebateVuePlugin"
import { defineGlobals } from "vue-utils"
import { ClaimType } from "@/steps"
import { httpJsonRequest, httpJsonRequestJsonResponse, httpRequest, RequestMethod } from "@/apis/httpService"
import { useLoading } from "@/composition/useLoading"
import type { Id } from "@/types"

const RefundReceivedForm = reactive({
	FeedbackId: 0,
	Received: null,
	Postcode: "",
	RatingId: 0,
	Score: null as number | null,
	AddressResults: {},
	Email: null,
	Address: null as string | null,
	Complete: false,
	CustomerId: 0,
	ClaimId: 0,
	EnquirySent: false,
	ComplaintSent: false,
	InvitationSent: false,
	Message: "",
	ClaimType: ClaimType.Standard,
})

defineGlobals({ RefundReceivedForm })

watch(
	() => RefundReceivedForm.Received,
	async () => {
		//fired when RefundReceivedForm.Received changes...
		RefundReceivedForm.FeedbackId = await createFeedbackRecord()
	}
)

watch(
	() => RefundReceivedForm.RatingId,
	async () => {
		if (RefundReceivedForm.FeedbackId >= 1) {
			await updateFeedbackRecord()
			//RefundReceivedForm.FeedbackId = r;

			if (testMode) console.log(RefundReceivedForm.Score)
			if (RefundReceivedForm.Score !== null && RefundReceivedForm.Score >= 5) {
				await queueRUKInvitation()
				if (testMode) console.log("sent RUK invitation request...")
				RefundReceivedForm.InvitationSent = true
			}
		} else {
			RefundReceivedForm.FeedbackId = await createFeedbackRecord()
		}
	}
)

const { runAction } = useLoading()

//initialize vue context
function vueInitRefundReceivedForm() {
	const app = createApp({
		data: () => ({
			RefundReceivedForm,
		}),
		methods: {
			frontEndDateFormat(date: string) {
				return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY")
			},
			async lookUpAddresses(e: Event) {
				const postcode = (e.target as HTMLInputElement).value.trim()
				if (postcode.length < 5) {
					if (postcode.length > 1) {
						void showModalMessage("Postcode search", "Please input a complete UK postcode.")
					}
					return
				}
				try {
					RefundReceivedForm.AddressResults = await addressLookup(postcode)
					const fixed = fixPostcode(postcode)
					RefundReceivedForm.Postcode = fixed
				} catch (e) {
					void showModalMessage(
						"Postcode search",
						"No addresses could be found for the postcode you supplied. Please ensure you have provided a valid UK RESIDENTIAL Postcode."
					)
				}
			},
			async selectAddress(e: Event) {
				const addressId = (e.target as HTMLInputElement).value
				const address = await getAddress(addressId)
				RefundReceivedForm.Address = `${address.Address1}, ${address.Address2}, ${address.Address3}, ${address.County}`
			},
		},
	})

	app.use(new TaxRebateVuePlugin())
	app.mount("#RefundReceivedForm")

	return app
}

export function refundReceivedFormInit() {
	if ($("#RefundReceivedForm").length) {
		vueInitRefundReceivedForm()
	}
}

async function createFeedbackRecord(): Promise<Id> {
	const data = {
		ClaimId: RefundReceivedForm.ClaimId,
		ReceivedRebate: RefundReceivedForm.Received === null ? false : RefundReceivedForm.Received,
		Rating: RefundReceivedForm.RatingId,
		ClaimType: RefundReceivedForm.ClaimType,
	}
	return await httpJsonRequestJsonResponse("/api/ui/customer/refundreceived/savefeedback", data, RequestMethod.POST)
}

async function updateFeedbackRecord(): Promise<Id> {
	const data = {
		ReceivedRebate: RefundReceivedForm.Received === null ? false : RefundReceivedForm.Received,
		Rating: RefundReceivedForm.RatingId,
		Details: RefundReceivedForm.Message,
	}
	return await httpJsonRequestJsonResponse(
		`/api/ui/customer/refundreceived/updatefeedback/${RefundReceivedForm.FeedbackId}`,
		data,
		RequestMethod.POST
	)
}

async function sendNotReceivedReport(): Promise<void> {
	const data = {
		Address: RefundReceivedForm.Address,
		Email: RefundReceivedForm.Email,
	}
	await httpJsonRequest(`/api/ui/customer/${RefundReceivedForm.CustomerId}/refundreceived/sendnotreceivedreport`, data, RequestMethod.POST)
}

async function sendNegativeFeedbackReport(): Promise<void> {
	const data = {
		FBId: RefundReceivedForm.FeedbackId,
		Complaint: RefundReceivedForm.Message,
	}
	await httpJsonRequest(`/api/ui/customer/${RefundReceivedForm.CustomerId}/refundreceived/sendnegativefeedbackreport`, data, RequestMethod.POST)
}

async function queueRUKInvitation(): Promise<void> {
	await httpRequest(`/api/ui/customer/${RefundReceivedForm.CustomerId}/refundreceived/rukinvitation`, RequestMethod.POST)
}

$(document).on("click", "#RefundReceivedForm button.no-refund-enquiry", async (e) => {
	e.preventDefault()

	await runAction(sendNotReceivedReport())
	RefundReceivedForm.EnquirySent = true
})

$(document).on("click", "#RefundReceivedForm button.review-invitation", async (e) => {
	e.preventDefault()

	await runAction(queueRUKInvitation())
	RefundReceivedForm.InvitationSent = true
})

$(document).on("click", "#RefundReceivedForm button.send-complaint", async (e) => {
	e.preventDefault()

	await runAction(sendNegativeFeedbackReport())
	RefundReceivedForm.ComplaintSent = true
})

$(document).on("click", "#RefundReceivedForm button.review-decline", (e) => {
	e.preventDefault()
	window.location.href = "/"
})
