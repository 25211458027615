import { defaultCustomerClaim, defaultMODClaim } from "@/defaults"
import { setClaimStep } from "@/step-handler"
import ClaimSteps from "@/steps"
import { defineGlobals } from "vue-utils"

export function closeClaimClearData() {
	clearLocalStorage()

	window.location.replace("/")
}

export async function clearClaimData() {
	clearLocalStorage()

	await setClaimStep(ClaimSteps.InitialStep, {
		initialLoad: true,
	})
	App.CustomerClaim = defaultCustomerClaim
	App.MODClaim = defaultMODClaim
	App.IsQuickEstimate = true
}

export function clearLocalStorage() {
	localStorage.removeItem("CustomerClaim")
	localStorage.removeItem("MODClaim")
	localStorage.removeItem("ClaimType")
	localStorage.removeItem("ClaimStage")
	localStorage.removeItem("MarriageClaim")
}

defineGlobals({ clearClaimData })
