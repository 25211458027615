import { closeClaimClearData } from "@/utils/clearClaim"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const DetailsPassedStep: ReactiveComponent = () => {
	function closeTax(e: Event) {
		e.preventDefault()
		closeClaimClearData()
	}

	return () => (
		<>
			<div class="col-12 text-center">
				<h2>Thank you</h2>
				<p>Thanks for showing an interest in claiming your tax rebate.</p>
				<p>
					We have passed your details to our partner,
					<span class="bold">Rift Group</span>
				</p>
			</div>
			<div class="col-12 my-2 text-center">
				<button type="button" class="btn btn-outline-primary cancel" onClick={closeTax}>
					Close <i class="fa fa-close"></i>
				</button>
			</div>
		</>
	)
}

export default defineComponent(DetailsPassedStep)
