import { defineComponent, type ReactiveComponent } from "vue-utils"

const WaitUntilNextTaxYear: ReactiveComponent = () => {
	return () => (
		<>
			<div class="col-12 mb-4 text-center">
				<h3 class="bold">Your Claim</h3>
			</div>
			<div class="d-flex flex-column align-items-center text-center">
				<p>Thank you for submitting your claim.</p>
				<p>
					We note from the information you provided that you have not yet been in employment for a full tax year. This means that we cannot yet
					send your claim to HMRC. We will submit your details to HMRC when your claim becomes eligible on the 6th April{" "}
					{new Date().getFullYear() + 1}.
				</p>
			</div>
		</>
	)
}

export default defineComponent(WaitUntilNextTaxYear)
