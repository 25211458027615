import { defineGlobals } from "vue-utils"
import { ClaimType, type ClaimStepData } from "./types"

export * from "./types"

const step = (step: number, name: string, type: ClaimType = ClaimType.Standard, extra: Partial<ClaimStepData> = {}): ClaimStepData => ({
	step,
	name,
	type,
	isIssue: false,
	...extra,
})

const issueStep = (step: number, name: string, type: ClaimType = ClaimType.Standard, extra: Partial<ClaimStepData> = {}): ClaimStepData => ({
	step,
	name,
	type,
	isIssue: true,
	getPreviousStep: () => null,
	getNextStep: () => null,
	shouldSkipStep: () => true,
	...extra,
})

const Postcode = step(1, "postcode")
const Name = step(2, "name")
const TaxYears = step(3, "tax-years")
const Qualifying = step(4, "qualifying")
const Address = step(5, "address")
const SelfAssessedChecks = step(6, "self-assessed-checks", ClaimType.Standard, {
	shouldSkipStep: (claim) => claim.selfAssessed === true || claim.selfAssessed === false,
})
const Estimate = step(7, "estimate")
const WorkFromHome = step(8, "work-from-home")
const Employer = step(9, "employer")
const Occupation = step(10, "occupation")
const Subscriptions = step(11, "subscriptions", ClaimType.Standard, {
	shouldSkipStep: (claim) => claim.Subscriptions.length === 0,
})
const SubscriptionPermission = step(12, "subscription-permission", ClaimType.Standard, {
	shouldSkipStep: (claim) => claim.Subscriptions.length === 0,
})
//currently set to skip this step since the subs data should already be populated by the OccupationSubscriptionYears data lookup (server side) and no need to show values to client.
const SubscriptionDetails = step(13, "subscription-details", ClaimType.Standard, {
	shouldSkipStep: () => true,
})
const NationalInsurance = step(14, "NI")
const ReviewClaim = step(15, "review")
const Signature = step(16, "signature", ClaimType.Standard, {})
const ClaimComplete = step(17, "complete")

const BasicInformation = step(3, "basic-information", ClaimType.MoD, {
	getPreviousStep: () => Employer,
})
const AboutYou = step(4, "about-you", ClaimType.MoD)
const DetailsPassed = step(5, "details-passed", ClaimType.MoD)

const NoTaxPaid = issueStep(99, "no-tax", ClaimType.Standard, {
	getPreviousStep: () => TaxYears,
})

const AlreadyClaimed = issueStep(100, "already-claimed")
const RequireSelfAssessment = issueStep(101, "require-sa", ClaimType.Standard, {
	getPreviousStep: () => Address,
})
const NoEmploymentHistory = issueStep(103, "no-employment-history")
const LackOfExpenses = issueStep(104, "lack-expenses")
const UniformClaimed = issueStep(106, "uniform-claimed", ClaimType.Standard, {
	getPreviousStep: () => Qualifying,
})
const EmployerDetailsNeeded = issueStep(107, "employer-details-needed", ClaimType.Standard, {
	getPreviousStep: () => Employer,
})
const WaitUntilNextTaxYear = issueStep(108, "wait-until-next-tax-year")
const DetailsRejected = issueStep(109, "cancelled-details-rejected")

export const ClaimSteps = {
	Postcode,
	Name,
	TaxYears,
	Qualifying,
	Address,
	SelfAssessedChecks,
	Estimate,
	WorkFromHome,
	Employer,
	Occupation,
	Subscriptions,
	SubscriptionDetails,
	SubscriptionPermission,
	NationalInsurance,
	ReviewClaim,
	Signature,
	ClaimComplete,

	BasicInformation,
	AboutYou,
	DetailsPassed,

	NoTaxPaid,
	AlreadyClaimed,
	RequireSelfAssessment,
	NoEmploymentHistory,
	LackOfExpenses,
	UniformClaimed,
	EmployerDetailsNeeded,
	WaitUntilNextTaxYear,
	DetailsRejected,

	InitialStep: Postcode,
	FinalStep: ClaimComplete,
	FinalMoDStep: DetailsPassed,
} as const

defineGlobals({
	ClaimSteps,
})

export default ClaimSteps
