import { ref, type InputHTMLAttributes } from "vue"
import { defineComponent, inferProps, useOnInputNumber, type ReactiveComponent } from "vue-utils"

interface Props {
	value: number
	setValue(value: number): void
	format?: Intl.NumberFormat
}

const gbpFormat = new Intl.NumberFormat("en-GB", { style: "currency", currency: "GBP" })

const CurrencyInput: ReactiveComponent<Props, InputHTMLAttributes> = (props, { attrs }) => {
	const inputRef = ref<HTMLInputElement>()
	const isFocus = ref(false)
	function handleInput(amount: number) {
		props.setValue(amount)
		inputRef.value?.blur()
	}
	function getValue() {
		if (isFocus.value) {
			return props.value
		}
		return (props.format ?? gbpFormat).format(props.value)
	}

	return () => (
		<input
			ref={inputRef}
			type={isFocus.value ? "number" : "text"}
			class="form-control"
			value={getValue()}
			onChange={useOnInputNumber(handleInput)}
			onBlur={() => (isFocus.value = false)}
			onFocus={() => (isFocus.value = true)}
			min={0}
			step={0.01}
			{...attrs}
		/>
	)
}

export default defineComponent(CurrencyInput, inferProps<Props>())
