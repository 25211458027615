import type { ClaimStepData } from "@/steps"
import ClaimSteps from "@/steps"

function shouldSkipStep(step: ClaimStepData) {
	if (!step.shouldSkipStep) {
		return false
	}
	return step.shouldSkipStep(App.CustomerClaim)
}

/**
 * @param currentStep The current step
 * @returns The next step to go to, ignoring any steps which are to be skipped
 */
export function getNextStep(currentStep: ClaimStepData): ClaimStepData | null {
	let newStep: ClaimStepData | null = currentStep
	do {
		newStep = getDirectNextStep(newStep)
	} while (newStep !== null && shouldSkipStep(newStep))

	return newStep
}

/**
 * @param currentStep The current step
 * @returns The direct next step, disregarding any stages which should be skipped
 */
export function getDirectNextStep(currentStep: ClaimStepData): ClaimStepData | null {
	if (currentStep.getNextStep) {
		const next = currentStep.getNextStep()
		if (next !== "auto") {
			return next
		}
	}

	const nextSteps = Object.values(ClaimSteps)
		.filter((step) => step.step > currentStep.step)
		.sort((s1, s2) => s1.step - s2.step)

	return nextSteps.find((s) => s.type === currentStep.type) ?? null
}

/**
 * @param currentStep The current step
 * @returns The previous step to go to, ignoring any steps which are to be skipped
 */
export function getPreviousStep(currentStep: ClaimStepData): ClaimStepData | null {
	let previousStep: ClaimStepData | null = currentStep
	do {
		previousStep = getDirectPreviousStep(previousStep)
	} while (previousStep !== null && shouldSkipStep(previousStep))

	return previousStep
}

/**
 * @param currentStep The current step
 * @returns The direct previous step, disregarding any stages which should be skipped
 */
export function getDirectPreviousStep(currentStep: ClaimStepData): ClaimStepData | null {
	if (currentStep.getPreviousStep) {
		const previous = currentStep.getPreviousStep()
		if (previous !== "auto") {
			return previous
		}
	}

	const previousSteps = Object.values(ClaimSteps)
		.filter((step) => step.step < currentStep.step)
		.sort((s1, s2) => s2.step - s1.step)
	const directPreviousStep = previousSteps.find((s) => s.type === currentStep.type)
	if (directPreviousStep) {
		return directPreviousStep
	}

	/*
		If no step is found with a lower step number and the same claim type, assume that the previous step was a different type
		This is feasible in the case of, say, entering into the MoD flow
	*/
	return previousSteps.length === 0 ? null : previousSteps[0]
}
