import { useLoading } from "@/composition/useLoading"
import ClaimSteps from "@/steps"
import type { Id } from "@/types"
import moment from "moment"
import { defineGlobals } from "vue-utils"
import { logCustomerIOEvent } from "../apis"

//TODO
export function fixHeadFoot(cStage = CurrentStep, minStage = ClaimSteps.Estimate) {
	if (cStage.step >= minStage.step) {
		// Add claim class to body to activate minimal header and fixed footer when at count up stage or later
		if (!$("body").hasClass("claim")) $("body").addClass("claim")
	}
}

export async function showTrustPilotModal(id: Id, tpUrl: string) {
	await useLoading().runAction(
		logCustomerIOEvent(id, {
			name: "5Star",
			// eslint-disable-next-line new-cap
			data: { claimid: App.CustomerClaim.ClaimId, timestamp: moment() },
		})
	)
	if (testMode) console.log("Sent 5 start event to CustIO")
	$("#Modal h5.modal-title").html("We're glad you're pleased!")
	const tpFrame = `<iframe width="100%" height="450" frameBorder="0" style="overflow:auto" src="https://www.trustpilot.com/evaluate/embed/${tpUrl}"></iframe>`
	$("#Modal .modal-body").html(tpFrame)
	$("#Modal").modal({
		keyboard: false,
	})
}

defineGlobals({ fixHeadFoot, showTrustPilotModal })
