import { renderSlot } from "vue"
import { defineComponent, inferProps, type ReactiveComponent } from "vue-utils"

interface Props {
	question: string
}

const Question: ReactiveComponent<Props> = (props, { slots }) => {
	return () => (
		<div class="col-12 my-2 text-center">
			<p class="small bold my-0">{props.question}</p>
			{renderSlot(slots, "default")}
		</div>
	)
}

export default defineComponent(Question, inferProps<Props>())
