import { closeClaimClearData } from "@/utils/clearClaim"
import { defineComponent, type ReactiveComponent } from "vue-utils"

const ResumeClaim: ReactiveComponent = () => {
	return () => (
		<div class="d-flex w-100 justify-content-center mt-2" style={{ columnGap: "2rem" }}>
			<button type="button" class="btn btn-outline-primary reset mb-2" onClick={() => closeClaimClearData()}>
				<i class="fa fa-arrow-circle-left" /> Start again
			</button>
			<button
				type="button"
				class="btn btn-outline-primary resume mb-2"
				onClick={() => {
					App.InitialLoadWithExistingClaim = false
					App.IsQuickEstimate = false
				}}
			>
				Carry on with your quote <i class="fa fa-arrow-circle-right"></i>
			</button>
		</div>
	)
}

export default defineComponent(ResumeClaim)
