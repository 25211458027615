import { insertQualifyingCheck } from "@/apis"
import ClaimSteps, { ClaimType } from "@/steps"
import { ClaimStatus } from "@/types"
import type { QualifyingCheck } from "."

export const EmployedCheck: QualifyingCheck = ({ claim }) => {
	if (CurrentStep.type === ClaimType.Standard && !claim.Employed) {
		claim.ClaimStatus = ClaimStatus.CancelledNoEmploymentHistory
		void insertQualifyingCheck("Do you have a current job?", "no", false, App.CustomerClaim.ClaimId, "UNEMP")
		return ClaimSteps.NoEmploymentHistory
	}

	void insertQualifyingCheck("Do you have a current job?", "yes", true, App.CustomerClaim.ClaimId, "UNEMP")
	return true
}
